import React from "react";
import useAxios from 'axios-hooks';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import HomeRounded from "@material-ui/icons/HomeRounded";
import Phone from "@material-ui/icons/Phone";
import ShareRounded from "@material-ui/icons/ShareRounded";
import Email from "@material-ui/icons/Email";

// core components
import InfoArea from "components/InfoArea/InfoArea.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import HeaderLinks from "components/Header/myHeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import {MapContainer,TileLayer,Marker,Popup} from 'react-leaflet';
import GLOBAL from 'global';
import styles from "assets/jss/material-kit-react/views/contactoView/contactoViewStyle";
import 'assets/css/contactoView/contactoView.css';

import logoVertical from "assets/img/logo-horizontal.png";
import Skeleton from "react-loading-skeleton";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function ContactoView(props) {
  const classes = useStyles();
  const { ...rest } = props;
  
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/inicio/getDespacho`
  );


  const position = [51.505, -0.09];
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const getPosition = () => {
    let posxy = [51.505, -0.09];
    if(data.data && data.data.despacho && data.data.despacho.latitud && data.data.despacho.longitud){
      posxy = [data.data.despacho.latitud,data.data.despacho.longitud];
    }
    return posxy;
  }
  if (error) return <SnackbarContent
    message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"} /></Button></div>}
    close
    color="danger"
    icon="info_outline"
  />  
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.img} alt="Logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />

      <Parallax small className={classes.customParalaxBg}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} style={{textAlign:'center'}}>
              <h1 className={classes.title}>CONTACTOS<br/><small style={{color:'white'}}>{data && data.status && data.data && data.data.despacho?data.data.despacho.nombre:''}</small></h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
        {
          error?
          <SnackbarContent
            message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"} /></Button></div>}
            close
            color="danger"
            icon="info_outline"
          />:null
        }
        {
          loading?
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Skeleton height="320px"/>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Skeleton height="320px"/>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Skeleton height="320px"/>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Skeleton height="320px"/>
            </GridItem>
          </GridContainer>:null
        }
        {
          data?
          <div className={classNames(classes.main, classes.mainRaised)}>        
            <div className={classes.container}>          
              <div className={classes.section}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>                    
                        <GridItem xs={12} sm={12} md={6}>
                          <InfoArea
                            title="Nuestras direcciones son:"
                            description={data.data && data.data.despacho?data.data.despacho.direccion:null}
                            icon={HomeRounded}
                            iconColor="primary"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <InfoArea
                            title="Nuestros teléfonos son:"
                            description={data.data && data.data.despacho?<span>{data.data.despacho.telefonos}<br/>{data.data.despacho.celularWhatsapp}</span>:''}
                            icon={Phone}
                            iconColor="primary"
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <InfoArea
                            title="Puedes seguirnos tambien en:"
                            description=''
                            icon={ShareRounded}
                            iconColor="primary"
                          />
                          <div style={{textAlign:'center'}}>
                            {
                              data.data && data.data.despacho && data.data.despacho.linkFacebook?
                              <Button color="primary" justIcon onClick={()=>window.open(data.data.despacho.linkFacebook,'_blank')}>
                                <i className={ " fab fa-facebook"} />
                              </Button>:null
                            }
                            {                          
                              data.data && data.data.despacho && data.data.despacho.linkTwitter?
                              <Button color="primary" justIcon onClick={()=>window.open(data.data.despacho.linkTwitter,'_blank')}>
                                <i className={ " fab fa-twitter"} />
                              </Button>:null
                            }
                            {
                              data.data && data.data.despacho && data.data.despacho.linkInstagram?
                              <Button color="primary" justIcon onClick={()=>window.open(data.data.despacho.linkInstagram,'_blank')}>
                                <i className={ " fab fa-instagram"} />
                              </Button>:null
                            }
                            {
                              data.data && data.data.despacho && data.data.despacho.linkYoutube?
                              <Button color="primary" justIcon onClick={()=>window.open(data.data.despacho.linkYoutube,'_blank')}>
                                <i className={ " fab fa-youtube"} />
                              </Button>:null
                            }
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <InfoArea
                            title="Nuestros correos son:"
                            description={data.data && data.data.despacho?data.data.despacho.email:null}
                            icon={Email}
                            iconColor="primary"
                          />
                        </GridItem>
                        
                      </GridContainer>
                    <br/>
                    <br/>
                    {
                      false?
                      <GridContainer>
                        <GridItem xs={12}>
                          <h4 className={classes.title} style={{color:'#3C4858'}}>Envía una sugerencia o un reclamo:</h4>    
                        </GridItem>                    
                        <GridItem xs={12} sm={12}>
                          <CustomInput
                            labelText="Ingrese su mensaje"
                            id="message"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.textArea
                            }}
                            inputProps={{
                              multiline: true,
                              rows: 5
                            }}
                          />
                          <Button color="primary">Enviar mensaje</Button>                    
                        </GridItem>
                      </GridContainer>:null
                    }

                    <GridContainer>
                      <GridItem xs={12}>
                        <h4 className={classes.title} style={{color:'#3C4858'}}>Nuestra ubicación es:</h4>    
                      </GridItem>                    
                      <GridItem xs={12} md={6}>
                        <MapContainer center={getPosition()} zoom={13} scrollWheelZoom={false}>
                          <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker position={getPosition()}>
                            <Popup>
                              {data.data && data.data.despacho?data.data.despacho.lugar:null}
                            </Popup>
                          </Marker>
                        </MapContainer>
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <img src={data.data && data.data.despacho?data.data.despacho.imagenDireccion:null} alt="..." height="480px"/>
                      </GridItem>
                    </GridContainer>

                  </GridItem>
                </GridContainer>
                <br/>
                <br/>
              </div>
            </div>
          </div>:null
        }
      <Footer />
    </div>
  );
}
