import React from "react";
import useAxios from 'axios-hooks';
import { withRouter } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from 'react-loading-skeleton';

// core components
import Tooltip from "@material-ui/core/Tooltip";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardMedia from '@material-ui/core/CardMedia';
import styles from "assets/jss/material-kit-react/views/inicioView/multimediaSection";
//extra components
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CardVideo from "components/Card/CardVideo";
import GLOBAL from 'global';
import Helpers from "helpers";
//import CardAudio from 'components/Card/CardAudio';
const useStyles = makeStyles(styles);

const MultimediaSection = (props) => {
  const classes = useStyles();
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/inicio/getVideosAudios`
  );
    
  if (error) return (
                    <div id='multimedia'>
                        <SnackbarContent
                            message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                            close
                            color="danger"
                            icon="info_outline"
                        />
                    </div>
                    )
    if (loading) return (
        <div id='multimedia'>
            <div className={classes.container}>
                <div className={classes.section}>
                    <h2><Skeleton width="100%"/></h2>
                    <h3><Skeleton width="100%"/></h3>
                    
                    <GridContainer>                            
                        <GridItem xs={12} sm={12} md={4} className='d-flex justify-content-center'>
                            <Skeleton width="100%" height="320px"/>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} className='d-flex justify-content-center'>
                            <Skeleton width="100%" height="320px"/>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} className='d-flex justify-content-center'>
                            <Skeleton width="100%" height="320px"/>
                        </GridItem>
                    </GridContainer>                    
                </div>                
            </div>
        </div>
    )
    return (
      <div id='multimedia'>
        <div className={classes.container}>
            <div className={classes.section}>
                <h2 className={classes.title} onClick={()=>props.history.push("/multimedia")}>
                    <Tooltip 
                        id="tooltip-top"
                        title="HAGA CLICK PARA IR A LA SECCIÓN DE MULTIMEDIA"
                        placement="top"
                    >
                    <span className={classes.spanalign}>MULTIMEDIA <p>(Ir a la sección)</p></span>
                    </Tooltip>
                </h2>
                <div style={{marginTop:"-40px"}}>
                    <GridContainer className={classes.justifyCenter}>
                        {
                            data.data && data.data.audiovideo?data.data.audiovideo.map((element,index)=>(
                                <GridItem key={`video${index}`} xs={12} sm={12} md={4} className='d-flex justify-content-center' style={{marginTop:'1px',marginBottom:'1px',height:"100%"}}>
                                    <CardVideo data={element}/>
                                </GridItem>
                            )):null
                        }
                    </GridContainer>
                </div>
            </div>            
            <div className={classes.section}>
                <h3 className={classes.subtitle} style={{cursor:'pointer'}} onClick={()=>props.history.push("/galeria")} >                    
                    <Tooltip 
                        id="tooltip-top"
                        title="HAGA CLICK PARA IR A LA SECCIÓN DE TU INTERÉS"
                        placement="top"
                    >
                    <span>DE TU INTERÉS</span>
                    </Tooltip>
                </h3>
                <div>
                    <GridContainer>
                        <GridItem>
                            <Carousel
                                showIndicators={true}
                                dynamicHeight={false}
                                autoPlay={true}
                                infiniteLoop={true}
                                interval={4000}
                                >
                                {
                                    data.data && data.data.galeria?data.data.galeria.map((item,index)=>(
                                        <div key={`img${index}`}>
                                            {/* <img src={item.url} className={classes.imagengaleria}/> */}
                                            <CardMedia
                                                className={classes.customImage}
                                                image={item.url}
                                                style={{backgroundSize:'contain',backgroundRepeat:'round',paddingTop:'1px',maxHeight:'500px',height:'500px'}}
                                            />
                                            <div className="legend" style={{width:'24%',background:'#24222291',textAlign:'start'}}>
                                                {/* {
                                                    item.titulo?
                                                    <h4>{item.titulo}</h4>:null
                                                }
                                                {
                                                    item.descripcion?
                                                    <p>{item.descripcion}</p>:null
                                                } */}
                                                <Button justIcon color="transparent" onClick={()=>Helpers.shareFacebook(item.url)}>
                                                    <i className={"fab fa-facebook"} />
                                                </Button>
                                                <Button justIcon color="transparent" onClick={()=>Helpers.shareTwitter(item.url,item.titulo)}>
                                                    <i className={"fab fa-twitter"} />
                                                </Button>
                                                <Button justIcon color="transparent" onClick={()=>Helpers.shareWhatsapp(item.url)}>
                                                    <i className={"fab fa-whatsapp"} />
                                                </Button>                                                
                                            </div>
                                        </div>
                                    )):null
                                }
                            </Carousel>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    </div>
  );
}

export default withRouter(MultimediaSection);