import React from "react";
import { Card, CardHeader, CardMedia, CardContent, Typography } from '@material-ui/core';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import Pagination from '@material-ui/lab/Pagination';
import HeaderLinks from "components/Header/myHeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import GLOBAL from 'global';
import CardVideo from "components/Card/CardVideo";
import { container, title } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";
import logoVertical from "assets/img/logo-horizontal.png";

// ReduxToolkit Por DavidSP-UGE
import { useSelector, useDispatch } from 'react-redux';
import { setAudio, setIsPlaying } from './../../redux/playerSlice';

// FontAwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRadio, faPause, faPlay } from '@fortawesome/free-solid-svg-icons'

const dashboardRoutes = [];

const styles = {
  container,
  title: {
    ...title,
    color: 'white',
    textAlign: 'center',
    width: '100%'
  },
  ...myStyles,
  justifyCenter: {
    justifyContent: "center !important"
  },
  customPadding: {
    paddingTop: '10px',
    paddingBottom: '10px',
  }
}

const imagenRadio = require("assets/img/GOBERNACIÓN RADIO-02.png").default;
const imagenReproduciendo = require("assets/img/gifoficial.gif").default;

const useStyles = makeStyles(styles);



export default function RadioView(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [page, setPage] = React.useState(1);

  // Redux por DSP_UGE
  const audio = useSelector((state) => state.player.audio);
  const isPlaying = useSelector((state) => state.player.isPlaying);
  const dispatch = useDispatch();


  const togglePlay = (url) => {
    if (audio && isPlaying) {
      // Pausar el audio
      audio.pause();
      dispatch(setIsPlaying(false));

    } else if (audio && !isPlaying) {
      // Reanudar el audio
      audio.play();
      dispatch(setIsPlaying(true));
    } else {
      // Reproducir nuevo audio
      const newAudio = new Audio(url);
      newAudio.play()
        .then(() => {
          dispatch(setAudio(newAudio));
          dispatch(setIsPlaying(true));
        })
        .catch((error) => {
          console.log('Error al reproducir el audio:', error);
        });
    }
  };

  // Estilos de boton
  const btnstyles = {
    playPauseButton: {
      border: '1px solid #cecdcdc0', // Agrega bordes redondeados
      borderRadius: '15px',
      width: '100%'
      // Agrega aquí otros estilos personalizados según tus necesidades
    },
  };

  // FIN Redux por DSP_UGE


  const handleChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(value);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small className={classes.customParalaxBg}>
        <h1 className={classes.title}>RADIO GOBERNACIÓN</h1>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section} style={{ display: 'flex', justifyContent: 'center' }} >
            <Card style={{ width: '500px' }}>
              <CardHeader title="RADIO EN LINEA" style={{ color: '#FFFFFF', textAlign: 'center', background: 'linear-gradient(60deg, #66cef6, #227dcb)' }} />
              <CardMedia
                component="img"
                alt="Imagen"
                height="auto"
                image={imagenRadio}
              />
              {/* Contenido */}
              <CardContent>
                <div>
                  {/* <button onClick={() => togglePlay("http://radios.istbolivia.com:8006/stream")}>{isPlaying ? "Pausar" : "Reproducir"}</button> */}
                  <Button
                    color="transparent"
                    style={btnstyles.playPauseButton}
                    // onClick={() => togglePlay("http://radios.istbolivia.com:8006/stream")} // Reemplaza con la URL de tu archivo de audio #1
                    onClick={() => togglePlay("https://live.turadiotv.com/8110/stream")} // Reemplaza con la URL de tu archivo de audio #1
                  >
                    {isPlaying ?
                      <div style={{ color: 'rgb(34, 125, 203)' }}>
                        <FontAwesomeIcon icon={faPause} />
                        <img
                          src={imagenReproduciendo}
                          alt="Equalizador"
                          style={{ width: '40px', height: 'auto' }}
                        />
                        <span style={{ marginLeft: '15px' }}>En curso</span>
                      </div>
                      :
                      <div style={{ color: 'rgb(34, 125, 203)' }} title={'Detener radio en linea'}>
                        <FontAwesomeIcon icon={faPlay} />
                        <span style={{ color: '#66666 !important' }}>Reproducir radio en linea</span>
                      </div>
                    }
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
