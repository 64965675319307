import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// Redux
import { Provider } from "react-redux";
import store from './../../redux/store';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/myHeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/inicioView.js";

import logoVertical from "assets/img/logo-horizontal.png";

// Sections for this page
import CarouselSection from "./Sections/CarouselSection";
import GuiaTramitesSection from "./Sections/GuiaTramitesSection";
import NoticiaSection from "./Sections/NoticiaSection";
import MultimediaSection from "./Sections/MultimediaSection";
import UltimasLeyesDecretosSection from "./Sections/UltimasLeyesDecretosSection";
import MultiSection from "./Sections/MultiSection";
import PlanesSection from "./Sections/PlanesSection";
import PublicidadSection from "./Sections/PublicidadSection";
import SistamasApoyoSection from "./Sections/SistemasApoyoSection";
import GobiernoEnLineaSection from "./Sections/GobiernoEnLineaSection";
import { Box, Modal, Typography } from "@material-ui/core";
//import ServiciosDepartamentalesSection from "./Sections/ServiciosDepartamentalesSection";
import imagenBg from '../../assets/img/gobiernoenlineaqrs/modal-uge.jpeg';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '90%',
  //bgcolor: 'background.paper',
  //bgColor: 'transparent',
  //border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  //background: `url(${imagenBg}) no-repeat center center fixed`,
  backgroundImage: `url(${imagenBg})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundColor: 'transparent',
};
//var elem = null;
export default function InicioView(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // useEffect(() => {
  //   console.log('llegooooooooooooooo');
  // }, [])

  return (
    <div>

      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.img} alt="Logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
        <CarouselSection />
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        {/* <GobiernoEnLineaSection /> */}
        <NoticiaSection />
        <MultimediaSection />
        <PublicidadSection />
        <PlanesSection />
        <GuiaTramitesSection />
        <SistamasApoyoSection />
        <UltimasLeyesDecretosSection />
        <MultiSection />
      </div>

      { 
      // Modal al inicio de la pagina web
      <div>
       <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
         
          
            <Box sx={style}>
                <a
              href="https://drive.google.com/drive/folders/19aXiqTCcWDGOUF9c9L7oqRShjMdipwcA?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
                color: 'inherit',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0
              }}
            >
              <div style={{ width: '100%', height: '100%' }}></div>
                <Typography
                variant="h6"
                component="span"
                style={{
                  position: 'absolute',
                  bottom: '20px',
                  width: '100%',
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  textAlign: 'center',
                  padding: '10px',
                }}
              >
                ¡Haz clic aquí!
              </Typography>
            </a>
              <Typography id="modal-modal-title" variant="h2" component="h2" style={{ textAlign: 'end', marginTop: '-60px' }}>
                <button
                  type="button"
                  onClick={handleClose}
                  style={{
                    borderRadius: '50%',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    padding: '10px',
                    border: '1px solid gray',
                    marginRight: '-50px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 0,
                    right: 0
                  }}
                >
                  X <br />
                  Cerrar
                </button>
              </Typography>
            </Box>
        </Modal>
      </div> 

       // FIN Modal al inicio de la pagina web
      }

      <Footer />

    </div>
  );
}
