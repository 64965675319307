import React from "react";
import useAxios from 'axios-hooks';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from 'components/CustomButtons/Button.js';
// @material-ui/icons
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";

import { container,title } from "assets/jss/material-kit-react.js";

//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from 'react-loading-skeleton';
import GLOBAL from 'global';

const styles = {
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        ...container
    },
    section: {
        padding: "40px 0",
        textAlign: "center"
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "10px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign:'left'
    },
    title2: {
        ...title,
        marginBottom: "1rem",
        marginTop: "10px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign:'center'
    },
    description: {
        color: "#999"
    },
    justifyCenter: {
        justifyContent: "center !important",
        'margin-top':"-30px"
    },
}

const useStyles = makeStyles(styles);

export default function SistemasApoyoSection() {
  const classes = useStyles();
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/sistemasapoyo/sistemas`
  );
    
  const downloadFile = (uri,nombre="archivo") => {
    let link = document.createElement("a");
    link.download = nombre;
    link.href = uri;
    
    link.click();    
    link = null;
  }

  if (error || (data && !data.status)) return <SnackbarContent
                        message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                        close
                        color="danger"
                        icon="info_outline"
                    />
  if (loading) return(
        <div className={classes.container}>
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}><Skeleton width='100%'/></h2>                    
                    </GridItem>
                </GridContainer>
                <div>
                    <GridContainer>                        
                        <GridItem xs={12} sm={12} md={4}>
                            <Skeleton width='100%' height='480px'/>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Skeleton width='100%' height='480px'/>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Skeleton width='100%' height='480px'/>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
  )

  return (
    <div>
        <div className={classes.container}>
            <div className={classes.section}>
                {
                    data.data.length>0
                    ?(
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <h2 className={classes.title}>SISTEMAS DE APOYO</h2>                    
                            </GridItem>
                        </GridContainer>
                    ):null
                }
                <div>
                    <GridContainer className={classes.justifyCenter}>
                        {
                            data.data.map((item,index)=>(
                                <GridItem key={`card${index}`} xs={12} sm={12} md={4} style={{paddingTop:'10px',paddingBottom:'10px'}}>                                    
                                    <Card style={{width: "100%",height:'80%',cursor:'pointer'}} onClick={()=>window.open(item.link, '_blank')}>                                        
                                        <img src={item.imagen} alt={item.titulo} width="100%"/>
                                        <h4 className={classes.title2}>{item.nombre}</h4>
                                    </Card>
                                </GridItem>
                            ))
                            
                        }
                    </GridContainer>
                </div>
            </div>
        </div>
    </div>
  );
}
