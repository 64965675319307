import React,{useRef} from "react";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from 'components/CustomButtons/Button.js';
// @material-ui/icons
import HistoryRounded from "@material-ui/icons/HistoryRounded";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import CalendarToday from "@material-ui/icons/CalendarToday";
import CloudDownload from "@material-ui/icons/CloudDownload";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";

// Custom Components
import ModalComponent from "components/Modal/ModalComponent";
import HoyEnLaHistoria from "./HoyEnLaHistoriaSection";
import AgendaSection from "./AgendaSection";
import hoyenlahistoria from "../../../assets/img/hoyenlahistoria.png";
import encuesta from "../../../assets/img/encuesta.png";
import agendaoficial from "../../../assets/img/agendaoficial.png";
import useAxios from 'axios-hooks';
import GLOBAL from 'global';
import Skeleton from 'react-loading-skeleton';

import styles from "assets/jss/material-kit-react/views/inicioView/multiSectionStyle";

const useStyles = makeStyles(styles);

const MultiSection = (props) => {

    const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/inicio/getTieneHistoriaEncuestaAgenda`
    );

    const classes = useStyles();
    const childRef = useRef();
    
    const downloadFile = (uri,nombre="archivo") => {
        let link = document.createElement("a");
        link.download = nombre;
        link.href = uri;   
        link.click();    
        link = null;
    } 

    if(loading){
        return (
            <div>
            <div className={classes.container}>
                <div className={classes.section}>                    
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                        <h2 className={classes.title}>VEA TAMBIÉN</h2>
                        </GridItem>
                    </GridContainer>
                    <div style={{marginTop:"-20px"}}>
                        <GridContainer>
                            <Skeleton height="480px"/>
                        </GridContainer>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    return (
        <div>
            <div className={classes.container}>
                <div className={classes.section}>
                    <ModalComponent ref={childRef}/>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                            {
                                (data.data && (data.data.existeHistoria || data.data.existeEncusta || data.data.existeAgenda))
                                ?<h2 className={classes.title}>VEA TAMBIÉN</h2>
                                :null
                            }
                        
                        </GridItem>
                    </GridContainer>
                    <div style={{marginTop:"-20px"}}>
                        <GridContainer>
                            {
                                (data.data && data.data.existeHistoria)
                                ?<GridItem xs={12} sm={12} md={4}>
                                    <Card className={classes.customCard}>
                                        <img 
                                            src={hoyenlahistoria} 
                                            alt='Sin Imagen' 
                                            width="100%"
                                            style={{cursor:'pointer'}}
                                            onClick={()=>childRef.current.setValuesModal('HOY EN LA HISTORIA',<HoyEnLaHistoria/>,null)}
                                        />
                                        {/* <CardBody>
                                            <InfoArea
                                                title='HOY EN LA HISTORIA'
                                                description='Cupidatat nostrud culpa reprehenderit enim laboris labore aute excepteur magna minim consectetur.'
                                                icon={HistoryRounded}
                                                iconColor="primary"
                                                vertical
                                            />
                                        </CardBody>
                                        <CardFooter className={classes.justifyCenter}>
                                            <Button color="primary" onClick={()=>childRef.current.setValuesModal('HOY EN LA HISTORIA',<HoyEnLaHistoria/>,null)}>
                                                <CloudDownload/>{" "}Ver más
                                            </Button>
                                        </CardFooter> */}
                                    </Card>
                                </GridItem>
                                :null
                            }
                            {
                                (data.data && data.data.existeEncuesta)
                                ?<GridItem xs={12} sm={12} md={4}>
                                    <Card className={classes.customCard}>
                                        <img 
                                            src={encuesta} 
                                            alt='Sin Imagen' 
                                            width="100%"
                                            style={{cursor:'pointer'}}
                                            onClick={()=>props.history.push("/encuesta")}
                                        />
                                        {/* <CardBody>
                                            <InfoArea
                                                title='ENCUESTA'
                                                description='Cupidatat nostrud culpa reprehenderit enim laboris labore aute excepteur magna minim consectetur.'
                                                icon={QuestionAnswer}
                                                iconColor="primary"
                                                vertical
                                            />
                                        </CardBody>
                                        <CardFooter className={classes.justifyCenter}>
                                            <Button color="primary" onClick={()=>props.history.push("/encuesta")}>
                                                <CloudDownload/>{" "}Ver más
                                            </Button>
                                        </CardFooter> */}
                                    </Card>
                                </GridItem>
                                :null
                            }
                            
                            {
                                (data.data && data.data.existeAgenda)
                                ?<GridItem xs={12} sm={12} md={4}>
                                    <Card className={classes.customCard}>
                                        <img 
                                            src={agendaoficial} 
                                            alt='Sin Imagen' 
                                            width="100%"
                                            style={{cursor:'pointer'}}
                                            onClick={()=>childRef.current.setValuesModal('AGENDA OFICIAL',<AgendaSection/>,null)}
                                        />
                                        {/* <CardBody>
                                            <InfoArea
                                                title='AGENDA OFICIAL'
                                                description='Cupidatat nostrud culpa reprehenderit enim laboris labore aute excepteur magna minim consectetur.'
                                                icon={CalendarToday}
                                                iconColor="primary"
                                                vertical
                                            />
                                        </CardBody>
                                        <CardFooter className={classes.justifyCenter}>
                                            <Button color="primary" onClick={()=>childRef.current.setValuesModal('AGENDA OFICIAL',<AgendaSection/>,null)}>
                                                <CloudDownload/>{" "}Ver más
                                            </Button>
                                        </CardFooter> */}
                                    </Card>
                                </GridItem>
                                :null
                            }
                        </GridContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(MultiSection);