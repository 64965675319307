const Helpers = {    
    shareFacebook:(link='')=>{
        if(link){
            let linkCodificado = encodeURI(link);
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${linkCodificado}`,"_blank");
        }
    },
    shareTwitter:(link='',text='')=>{
        console.log("link=",link);
        if(link){
            let linkCodificado = encodeURI(link);
            window.open(`https://twitter.com/intent/tweet?url=${linkCodificado}&text=${text}`,"_blank");
        }
    },
    shareWhatsapp:(link=null)=>{
        if(link){
            let linkCodificado = encodeURI(link);
            window.open(`https://web.whatsapp.com/send?text=${linkCodificado}`,"_blank");
        }
    },
}
export default Helpers;