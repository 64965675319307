import React from 'react';
import useAxios from 'axios-hooks';
import Button from "components/CustomButtons/Button.js";
import { Document, Page } from 'react-pdf';
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from 'react-loading-skeleton';
import GLOBAL from 'global';

const AgendaSection = () => {
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
        
    const [{ data, loading, error }, refetch] = useAxios(
        `${GLOBAL.apiURL}/api/agenda/hoyAgenda`
    );
    if (error) return <SnackbarContent
                        message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                        close
                        color="danger"
                        icon="info_outline"
                    />
    if (loading) 
        return(
            <div>
                <Skeleton width='100%' height='320px'/>
                <Skeleton width='100%'/>
            </div>
        )
    if (data && data.status && data.data && data.data.length==0) return <SnackbarContent
                                                                                message={<div><b>No hay datos para esta fecha</b></div>}
                                                                                close
                                                                                color="info"
                                                                                icon="info_outline"
                                                                            />
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
                                                                            
    return (
        <div>
            <iframe width="100%" height="520px" src={data.data.archivo}></iframe>
        </div>
    );
}

export default AgendaSection;