import React from "react";
import { withRouter,useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// @material-ui/core components
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from 'components/CustomButtons/Button.js';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardNoticia from 'components/Card/CardNoticia';
import styles from "assets/jss/material-kit-react/views/inicioView/noticiasSectionStyle";
//import styles2 from "assets/jss/material-kit-react/tooltipsStyle.js";

import 'assets/css/inicioView/noticiaSection/noticiaSectionStyle.css';
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from 'react-loading-skeleton';
import GLOBAL from 'global';
/*
const myStyles = {
  ...styles,
  ...styles2
};
*/
const useStyles = makeStyles(styles);

const ChildNoticiaSection = (props) => {
  const { child } = useParams();
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/unidad/noticiasInicio/${child}`
  );

  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: false,
    adaptiveHeight:false,
    //fade:true,
    vertical:true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll:2,
    arrows: false,
    className:"slides"
  };
  
  if (error) return( 
                    <div id='noticias'>
                      <SnackbarContent
                        message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                        close
                        color="danger"
                        icon="info_outline"
                      />
                    </div>)
  if (loading) return(
    <div id='noticias' className={classes.container}>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}><Skeleton/></h2>            
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>          
            <Skeleton width='100%' height='480px'/>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Skeleton width='100%' height='480px'/>
          </GridItem>           
        </GridContainer>        
      </div>
    </div>
  )

  return (
      (data && data.data && data.data[0])? 
      ( 
        <div id='noticias' className={classes.container}>
          <div className={classes.section}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>           
                <h2 className={classes.title} onClick={()=>props.history.push("/unidad/"+child+"/noticias")}>
                  <Tooltip 
                    id="tooltip-top"
                    title="HAGA CLICK PARA IR A LA SECCIÓN DE NOTICIAS"
                    placement="top"
                  >
                    <span className={classes.spanalign}>ÚLTIMAS NOTICIAS <p>(Ir a la sección)</p></span>
                  </Tooltip>
                </h2>            
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>          
                {
                  data && data.data && data.data[0]?
                  <CardNoticia data={data.data[0]}/>:null
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{maxHeight:'414px',overflowY:'scroll'}}>
                    {
                      data && data.data && data.data[1]?
                      <CardNoticia preview data={data.data[1]}/>:null
                    }
                    {
                      data && data.data && data.data[2]?
                      <CardNoticia preview data={data.data[2]}/>:null
                    }
                    {
                      data && data.data && data.data[3]?
                      <CardNoticia preview data={data.data[3]}/>:null
                    }
                </div>            
              </GridItem>           
            </GridContainer> 
          </div>
        </div>
      ): null
  );
}

export default withRouter(ChildNoticiaSection);