import React,{forwardRef,useImperativeHandle} from 'react';
// material-ui components
import {makeStyles} from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ModalComponent = forwardRef((props,ref) => {
    const [data,setData] = React.useState({title:'',content:null,btnClose:null});
    const [modal, setModal] = React.useState(false);
    const classes = useStyles();    
    
    useImperativeHandle(
        ref,
        () => ({
            setValuesModal(_title='',_content=null,_btnClose=null) {
                setData({title:_title,content:_content,btnClose:_btnClose});
                setModal(true);
            }
        }),
    )
    return (
        <Dialog
            fullWidth
            classes={{
                root: classes.center,
                paper: classes.modal
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setModal(false)}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
                <IconButton
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={() => setModal(false)}
                >
                    <Close className={classes.modalClose} />
                </IconButton>
                <h4 className={classes.modalTitle}>{data.title}</h4>
            </DialogTitle>
            <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
            >
                {data.content}
            </DialogContent>
            {
                data.btnClose?
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}
                >
                    <Button color="primary" onClick={() => setModal(false)}>{data.btnClose}</Button>
                </DialogActions>:null
            }
        </Dialog>    
  );
})
export default ModalComponent;