import React from "react";
import { useParams,withRouter } from "react-router-dom";
import useAxios from 'axios-hooks';
//import useAxios from 'axios-hooks';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Pagination from '@material-ui/lab/Pagination';

import HeaderLinks from "components/Header/ChildHeaderLinks";

import Parallax from "components/Parallax/Parallax.js";

//import CardNoticiaPreview from "./Components/CardNoticiaPreview";
import CardSidebarComponent from "./Components/CardSidebarComponent";
//import NoticiasComponent from "./Components/NoticiasComponent";
import CardNoticiaPreview from "views/Noticia/Components/CardNoticiaPreview";
import NoticiaDetalleComponent from "./Components/NoticiaDetalleComponent";
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
//import Skeleton from 'react-loading-skeleton';
import GLOBAL from 'global';

// styles
import { container, title } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";

import logoVertical from "assets/img/logo-horizontal.png";
const dashboardRoutes = [];

const styles = {
  container,
  title:{
    ...title,
    color:'white',
    textAlign:'center',
    width:'100%'
  },
  ...myStyles,
  
}

const useStyles = makeStyles(styles);

const NoticiaView = (props) => {
  const classes = useStyles();  
  const { ...rest } = props;
  const [page, setPage] = React.useState(1);
  const [categoria,setCategoria] = React.useState('');
  const [tag,setTag] = React.useState('');
  const [search,setSearch] = React.useState('');  
  const { id,child } = useParams();
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/unidad/noticias/${child}?categoria=${categoria}&palabra=${tag}&search=${search}`
);

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  const goToDetail = (dato) => {    
    window.scrollTo({ top: 0, behavior: 'smooth' });
    props.history.push(`/unidad/${child}/noticia-detalle/${dato.slug}`);
  }
  const handleChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(value);
  };
  const onChangeCategory = (category) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(1);
    setCategoria(category);
  }
  const onChangeTag = (etiqueta) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(1);
    setTag(etiqueta)
  }
  const onHandleSearch = (mysearch) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(1);
    setSearch(mysearch);
    if(id){
      props.history.push(`/unidad/${child}/noticias`);
    }
  }
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small className={classes.customParalaxBg}>
          <h1 className={classes.title}>NOTICIAS<br/><small style={{color:'white'}}>{data && data.status?String(data.message).toUpperCase():''}</small></h1>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>                
                <CardSidebarComponent childId={child} onChangeTag={(mytag)=>onChangeTag(mytag)} onChangeCategoria={(mycategory)=>onChangeCategory(mycategory)} onChangeSearch={(mysearch)=>onHandleSearch(mysearch)}/>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                {
                  !id?
                  <>
                    {
                      error || (data && !data.status)?
                      <SnackbarContent
                          message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple onclick={()=>refetch}><i className={" fab fa-refresh"}/></Button></div>}
                          close
                          color="danger"
                          icon="info_outline"
                      />:null
                    }
                    {
                      loading?<CardNoticiaPreview loading/>:null
                    }
                    {
                      data && data.data && data.data.data && data.data.data.length===0?
                      <SnackbarContent
                          message={<div><span>Sin noticias para mostrar</span></div>}
                          close
                          color="info"
                          icon="info_outline"
                      />:null
                    }
                    { 
                      data && data.data && data.data.data && data.data.data.length>0?
                      <>
                        {
                          data.data.data.map((item,index)=>(                        
                            <CardNoticiaPreview key={`preview${index}`} data={item} onHandleClick={()=>goToDetail(item)}/>
                          ))
                        }
                      <Pagination count={data.data.last_page} page={page} onChange={handleChange} />
                      </>:null
                    }
                  </>:<NoticiaDetalleComponent/>
                }
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default withRouter(NoticiaView);