import React from 'react';
import Button from "components/CustomButtons/Button.js";
import {withRouter} from "react-router-dom";
import CardMedia from '@material-ui/core/CardMedia';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import cardNoticiaStyles from "assets/jss/material-kit-react/views/inicioView/cardNoticia";
import 'assets/css/inicioView/noticiaSection/cardNoticia.css';
import moment from "moment";
import 'moment/locale/es';

import Helpers from "helpers";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Skeleton from 'react-loading-skeleton';

moment.locale('es');
const useStyles = makeStyles(cardNoticiaStyles);

const CardGaleria = (props) => {
    const {loading=false} = props;
    const {preview=false,data=null} = props;
    const classes = useStyles();

    if(loading) return (
        <Card>
          {
            <Skeleton height="480px"/>
          }
          <CardBody style={{paddingBottom:'0px'}}>        
            <h4 className={classes.cardTitle}><Skeleton/></h4>        
            <p><Skeleton/></p>
          </CardBody>
        </Card>
    );

    return (
        <figure className='customCard' style={{width:'1110px',height:'500px'}}>
        
            <CardMedia
                className={classes.customImage}
                style={{width:'1110px',height:'500px',paddingTop:'1px'}}
                image={data.imagen}
                onClick={()=> window.open(`${data.imagen}`, "_blank")}
                title={String(data.titulo).toUpperCase()}
            />      
            <figcaption className='customCaptionSocial'>
            <Button
                justIcon
                color="transparent"
                className={classes.margin5}
                onClick={()=>Helpers.shareFacebook(data.imagen)}
            >
                <i className={classes.socials + " fab fa-facebook-square"} style={{color:'white'}}/>
            </Button>
            <Button
                justIcon
                color="transparent"
                className={classes.margin5}
                onClick={()=>Helpers.shareTwitter(data.imagen)}
            >
                <i className={classes.socials + " fab fa-twitter"} style={{color:'white'}}/>
            </Button>
            <Button
                justIcon
                color="transparent"
                className={classes.margin5}
                onClick={()=>Helpers.shareWhatsapp(data.imagen)}
            >
                <i className={classes.socials + " fab fa-whatsapp"} style={{color:'white'}}/>
            </Button>
            </figcaption>

            <figcaption style={{textAlign:'center'}} className={classes.cardTitle+' customCaption'}>
            <span>{String(data.titulo).toUpperCase()}</span>
            <br/>
            {
                data.descripcion?
                <small className={classes.customSmallText}>{data.descripcion}</small>:null
            }
            </figcaption>
            
        </figure> 
    );
}

export default withRouter(CardGaleria);
