import React from "react";
import classNames from "classnames";
//import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import Pagination from '@material-ui/lab/Pagination';
import HeaderLinks from "components/Header/myHeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import decreto from "assets/img/decreto.jpg";
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import GLOBAL from 'global';

// styles
import { container, title,cardTitle } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

import BookOutlined from "@material-ui/icons/BookOutlined";
import OpenInBrowserRounded from "@material-ui/icons/OpenInBrowserRounded";
import TimeToLeave from "@material-ui/icons/Timer";

import logoVertical from "assets/img/logo-horizontal.png";
import Skeleton from "react-loading-skeleton";

import CustomInput from "components/CustomInput/CustomInput.js";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import Search from "@material-ui/icons/Search";

const dashboardRoutes = [];

const styles = {
    ...imagesStyle,
    container,
    title:{
        ...title,
        color:'white',
        textAlign:'center',
        width:'100%'
    },
    ...myStyles,
    justifyCenter: {
        justifyContent: "center !important"
    },
    customPadding:{
        paddingTop:'10px',
        paddingBottom:'10px',
    },
    customCard:{
        height:'90%'        
    },
    itemGrid: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    cardTitle:{
        ...cardTitle,
        textAlign:'center'
    },
    smallTitle: {
        color: "#6c757d",        
    },
    description: {
        color: "#999",
        textAlign:"justify",
        whiteSpace: 'pre-line'
    },
    inputRootCustomClasses: {
        margin: "0!important",        
    },
    formControl: {
        margin: "0 !important",
        paddingTop: "0",
        width:"80%"
    },
    searchIcon: {
        width: "20px",
        height: "20px",
        color: "inherit"
    },
}

const useStyles = makeStyles(styles);


const MyCard = (props) => {
    const {loading=false} = props;
    const classes = useStyles();
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
      );
    if(loading) return(        
        <Card className={classes.customCard}>
            <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <Skeleton circle width="250px"/>
            </GridItem>
            <h4 className={classes.cardTitle}>
                <Skeleton/>
                <br />
                <small className={classes.smallTitle}><Skeleton/></small>
                <small className={classes.smallTitle}><Skeleton/></small>
            </h4>
            <CardBody>
                <p className={classes.description}><Skeleton/></p>
            </CardBody>
            <CardFooter className={classes.justifyCenter}>
                <Skeleton width="250px"/>
            </CardFooter>
        </Card>
    )
    
    return(
        <Card className={classes.customCard}>
            <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={decreto} alt={props.item.titulo} className={imageClasses} />
            </GridItem>
                <h4 className={classes.cardTitle}>
                    {props.item.titulo}
                    {/* <br />
                    <small className={classes.smallTitle}>{props.item.tipo}</small> */}
                    {/* <br />
                    <small className={classes.smallTitle}>{props.item.fechapromulgacion}</small> */}
                </h4>
            <CardBody>                
                <p className={classes.description}><b>Resumen: </b>{props.item.resumen}</p>
                {/* <p className={classes.description}><b>Contenido: </b>{props.item.contenido}</p> */}
                {
                    (props.item.tipo == "3")? (
                        <>
                            <p className={classes.description}><b>Fecha Promulgación: </b>{props.item.fechapromulgacion}</p>
                            <p className={classes.description}><b>Fecha Publicación: </b>{props.item.fechaaprobacion}</p>
                            <p className={classes.description}><b>Numero Documento: </b>{props.item.numerodocumento}</p>
                        </>
                    )
                    :(
                        <>
                            <p className={classes.description}><b>Fecha Publicación: </b>{props.item.fechapromulgacion}</p>
                            <p className={classes.description}><b>Fecha Emisión: </b>{props.item.fechaaprobacion}</p>
                            <p className={classes.description}><b>Numero Documento: </b>{props.item.numerodocumento}</p>
                        </>
                    )
                }
            </CardBody>
            <CardFooter className={classes.justifyCenter}>
                <Button
                    color="primary"
                    className={classes.margin5}
                    onClick={()=>window.open(props.item.archivo, '_blank')}
                    >
                        <OpenInBrowserRounded/>{' '}Descargar
                </Button>
            </CardFooter>
        </Card>
    )
}

export default function LeyesDecretosView(props) {
  const classes = useStyles();  
    
  const { ...rest } = props;
  const [ page,setPage ] = React.useState(1);
  const [ tipo,setTipo ] = React.useState('4');
  const [ search,setSearch ] = React.useState('');
  const [finding,setFinding] = React.useState(false);
  const [ text,setText ] = React.useState('');
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/legales/documentos?limite=12&search=${search}&tipo=${tipo}&page=${page}`
  );
  const onHandleClick = (myparam) => {
    switch(myparam){
        case 0:
            setTipo('4');
            break;
        case 1:
            setTipo('3');
            break;
        case 2:
            setTipo('1');
            break;
        case 3:
            setTipo('2');
            break;
        case 4:
            setTipo('5');
            break;
        case 5:
            setTipo('6');
            break;
    }
  }
  const handleChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(value);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
    const onHandleChange = (event) => {        
        setText(event.target.value);
    }
    const onHandleClickSearch = () => {
        if(finding){
            setFinding(!finding);
            setSearch('');
            setText('');
            //props.onChangeSearch('');
        }else{
            setFinding(!finding);
            setSearch(text);            
            setPage(1);
            //props.onChangeSearch(search);
            //console.log("enviara search=",search);
        }
    }
  
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small className={classes.customParalaxBg}>
          <h1 className={classes.title}>LEYES Y DECRETOS</h1>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={12} md={6}>
                    <div>
                        <CustomInput
                            primary
                            inputRootCustomClasses={classes.inputRootCustomClasses}
                            formControlProps={{
                                className: classes.formControl,
                            }}
                            inputProps={{
                                disabled:finding,
                                value:text,
                                onChange:onHandleChange,
                                placeholder: "Buscar",
                                inputProps: {
                                "aria-label": "Buscar",
                                className: classes.searchInput
                                }
                            }}
                        />
                        <Button justIcon round color="primary" disabled={text.toString().trim().length===0} onClick={()=>onHandleClickSearch()}>
                            {finding?<CloseOutlined className={classes.searchIcon} />:<Search className={classes.searchIcon} />}
                        </Button>
                    </div>
                </GridItem>
            </GridContainer>
            <NavPills
                color="primary"
                onHandleClick={onHandleClick}
                tabs={[
                {
                    tabButton: "ESTATUTO DEPARTAMENTAL",
                    tabIcon: BookOutlined,
                    tabContent: (
                        <div style={{marginTop:'-30px',overflowX:'hidden',overflowY:'hidden',padding:'40px',textAlign:'center'}}>                            
                            <GridContainer className={classes.justifyCenter}>                                                  
                                {
                                    !loading && data && data.data && data.data.data?data.data.data.map((item,index)=>(
                                    <GridItem key={`card${index}`} xs={12} sm={12} md={6} lg={4} className={classes.customPadding}>                    
                                        <MyCard item={item}/>
                                    </GridItem>
                                    )):null
                                }
                            </GridContainer>
                        </div>
                    )
                },
                {
                    tabButton: "LEY DEPARTAMENTAL",
                    tabIcon: BookOutlined,
                    tabContent: (
                        <div style={{marginTop:'-30px',overflowX:'hidden',overflowY:'hidden',padding:'40px',textAlign:'center'}}>                            
                            <GridContainer className={classes.justifyCenter}>                            
                                {
                                    !loading && data && data.data && data.data.data?data.data.data.map((item,index)=>(
                                    <GridItem key={`card${index}`} xs={12} sm={12} md={6} lg={4} className={classes.customPadding}>                    
                                        <MyCard item={item}/>
                                    </GridItem>
                                    )):null
                                }
                            </GridContainer>
                        </div>
                    )
                },
                {
                    tabButton: "DECRETO DEPARTAMENTAL",
                    tabIcon: BookOutlined,
                    tabContent: (
                        <div style={{marginTop:'-30px',overflowX:'hidden',overflowY:'hidden',padding:'40px',textAlign:'center'}}>                            
                            <GridContainer className={classes.justifyCenter}>                            
                                {
                                    !loading && data && data.data && data.data.data?data.data.data.map((item,index)=>(
                                    <GridItem key={`card${index}`} xs={12} sm={12} md={6} lg={4} className={classes.customPadding}>                    
                                        <MyCard item={item}/>
                                    </GridItem>
                                    )):null
                                }
                            </GridContainer>
                        </div>
                    )
                },
                {
                    tabButton: "DECRETO GOBERNATIVO",
                    tabIcon: BookOutlined,
                    tabContent: (
                        <div style={{marginTop:'-30px',overflowX:'hidden',overflowY:'hidden',padding:'40px',textAlign:'center'}}>                            
                            <GridContainer className={classes.justifyCenter}>                            
                                {
                                    !loading && data && data.data && data.data.data?data.data.data.map((item,index)=>(
                                    <GridItem key={`card${index}`} xs={12} sm={12} md={6} lg={4} className={classes.customPadding}>                    
                                        <MyCard item={item}/>
                                    </GridItem>
                                    )):null
                                }
                            </GridContainer>
                        </div>
                    )
                },
                {
                    tabButton: "RESOLUCIÓN DEPARTAMENTAL",
                    tabIcon: BookOutlined,
                    tabContent: (
                        <div style={{marginTop:'-30px',overflowX:'hidden',overflowY:'hidden',padding:'40px',textAlign:'center'}}>                            
                            <GridContainer className={classes.justifyCenter}>                            
                                {
                                    !loading && data && data.data && data.data.data?data.data.data.map((item,index)=>(
                                    <GridItem key={`card${index}`} xs={12} sm={12} md={6} lg={4} className={classes.customPadding}>                    
                                        <MyCard item={item}/>
                                    </GridItem>
                                    )):null
                                }
                            </GridContainer>
                        </div>
                    )
                },
                {
                    tabButton: "RESOLUCIÓN ADMINISTRATIVA",
                    tabIcon: BookOutlined,
                    tabContent: (
                        <div style={{marginTop:'-30px',overflowX:'hidden',overflowY:'hidden',padding:'40px',textAlign:'center'}}>                            
                            <GridContainer className={classes.justifyCenter}>                            
                                {
                                    !loading && data && data.data && data.data.data?data.data.data.map((item,index)=>(
                                    <GridItem key={`card${index}`} xs={12} sm={12} md={6} lg={4} className={classes.customPadding}>                    
                                        <MyCard item={item}/>
                                    </GridItem>
                                    )):null
                                }
                            </GridContainer>
                        </div>
                    )
                },
                ]}
            />
            <GridContainer className={classes.justifyCenter}>
                {
                    loading?
                    Array.from(new Array(3)).map((item,index)=>(
                    <GridItem key={`card${index}`} xs={12} sm={12} md={6} lg={4} className={classes.customPadding}>                    
                        <MyCard loading/>
                    </GridItem>
                    )):null
                }
                <GridItem xs={12}>                    
                    {
                        error || (data && !data.status)?
                        <SnackbarContent
                            message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button simple><i className={" fab fa-refresh"}/></Button></div>}
                            close
                            color="danger"
                            icon="info_outline"
                        />:null
                    }
                    {
                        data && data.status && data.data.data.length===0?
                        <SnackbarContent
                            message={<div><span>{text.length>0?'No hay resultados para su busqueda.':'No hay documentos publicados.'}</span><Button simple><i className={" fab fa-refresh"}/></Button></div>}
                            close
                            color="info"
                            icon="info_outline"
                        />:null
                    }
                </GridItem>            
            </GridContainer>
            <br/>
            <br/>
            <GridContainer className={classes.justifyCenter}>
              <GridItem xs={12}>
                <Pagination count={data && data.status?data.data.last_page:1} page={page} onChange={handleChange} />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
