import React from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import Pagination from '@material-ui/lab/Pagination';
import HeaderLinks from "components/Header/ChildHeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import GLOBAL from 'global';

// styles
import { container, title,cardTitle } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

import BookOutlined from "@material-ui/icons/BookOutlined";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import TabOutlined from "@material-ui/icons/TabOutlined";
import Search from "@material-ui/icons/Search";
import CloseOutlined from "@material-ui/icons/CloseOutlined";

import logoVertical from "assets/img/logo-horizontal.png";
import Skeleton from "react-loading-skeleton";
const dashboardRoutes = [];

const styles = {
    ...imagesStyle,
    container,
    title:{
        ...title,
        color:'white',
        textAlign:'center',
        width:'100%'
    },
    ...myStyles,
    justifyCenter: {
        justifyContent: "center !important"
    },
    justifyCenter2: {
        justifyContent: "center !important",
        color: "red"
    },
    customPadding:{
        paddingTop:'10px',
        paddingBottom:'10px',
    },
    customCard:{
        height:'90%'        
    },
    itemGrid: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    cardTitle:{
        ...cardTitle,
        textAlign:'center'
    },
    smallTitle: {
        color: "#6c757d",        
    },
    description: {
        color: "#999",
        textAlign:"justify"
    },
    inputRootCustomClasses: {
        margin: "0!important",        
    },
    formControl: {
        margin: "0 !important",
        paddingTop: "0",
        width:"80%"
    },
    searchIcon: {
        width: "20px",
        height: "20px",
        color: "inherit"
    },
}

const useStyles = makeStyles(styles);


const MyCard = (props) => {
    const {loading=false} = props;
    const classes = useStyles();
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
      );
    if(loading) return(        
        <Skeleton height="320"/>
    )
    return(
        <Card style={{width: "100%",height:'100%'}}>
            <CardBody style={{textAlign:'center'}}>
                <InfoArea
                    title={props.item.titulo}
                    description={props.item.resumen}
                    icon={PictureAsPdf}
                    iconColor="primary"
                    vertical
                />
            </CardBody>
            <CardFooter className={classes.justifyCenter}>
                <Button color="primary" onClick={()=>window.open(props.item.archivo, '_blank')}>
                    <TabOutlined/>{" "}Abrir
                </Button>
            </CardFooter>
        </Card>
    )
}



export default function ChildDocumentosView(props) {
  const classes = useStyles();  
    const {child} = useParams();
  const { ...rest } = props;
  const [ page,setPage ] = React.useState(1);
  const [ search,setSearch ] = React.useState('');
  const [ text,setText ] = React.useState('');
  const [finding,setFinding] = React.useState(false);
  const [ tipo,setTipo ] = React.useState('Convocatorias');
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/unidad/documentos/${child}?limite=10&search=${search}&tipo=${tipo}&page=${page}`
  );
  const onHandleClick = (myparam) => {
    switch(myparam){
        case 0:
            setTipo('Convocatorias');
            break;
        case 1:
            setTipo('Resoluciones');
            break;
        case 2:
            setTipo('Reglamentos');
            break;        
    }
  }
  const handleChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(value);
  }
  const onHandleChange = (event) => {        
        setText(event.target.value);
    }
  const onHandleClickSearch = () => {
        if(finding){
            setFinding(!finding);
            setSearch('');
            setText('');
            //props.onChangeSearch('');
        }else{
            setFinding(!finding);
            setSearch(text);
            //props.onChangeSearch(search);
            //console.log("enviara search=",search);
        }
    }
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []); 
  
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small className={classes.customParalaxBg}>
          <h1 className={classes.title}>DOCUMENTOS<br/><small style={{color:'white'}}>{data && data.status?String(data.message).toUpperCase():''}</small></h1>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={6}>
                    <div>
                        <CustomInput
                            primary
                            inputRootCustomClasses={classes.inputRootCustomClasses}
                            formControlProps={{
                                className: classes.formControl,
                            }}
                            inputProps={{
                                disabled:finding,
                                value:text,
                                onChange:onHandleChange,
                                placeholder: "Buscar",
                                inputProps: {
                                "aria-label": "Buscar",
                                className: classes.searchInput
                                }
                            }}
                        />
                        <Button justIcon round color="primary" disabled={text.toString().trim().length===0} onClick={()=>onHandleClickSearch()}>
                            {finding?<CloseOutlined className={classes.searchIcon} />:<Search className={classes.searchIcon} />}
                        </Button>
                    </div>
                </GridItem>      
            </GridContainer>            
            <NavPills
                color="primary"
                onHandleClick={onHandleClick}
                tabs={[
                {
                    tabButton: "CONVOCATORIAS",
                    tabIcon: BookOutlined,
                    tabContent: (
                        <div style={{marginTop:'-50px',overflowX:'hidden',overflowY:'hidden',padding:'40px',textAlign:'center'}}>
                            <GridContainer className={classes.justifyCenter2}>                                                  
                                {
                                    !loading && data && data.data && data.data.data?data.data.data.map((item,index)=>(
                                    <GridItem key={`card${index}`} xs={12} sm={12} md={6} lg={4} className={classes.customPadding}>                    
                                        <MyCard item={item}/>
                                    </GridItem>
                                    )):null
                                }
                            </GridContainer>
                        </div>
                    )
                },
                {
                    tabButton: "RESOLUCIONES",
                    tabIcon: BookOutlined,
                    tabContent: (
                        <div style={{marginTop:'-50px',overflowX:'hidden',overflowY:'hidden',padding:'40px',textAlign:'center'}}>
                            <GridContainer className={classes.justifyCenter}>                            
                                {
                                    !loading && data && data.data && data.data.data?data.data.data.map((item,index)=>(
                                    <GridItem key={`card${index}`} xs={12} sm={12} md={6} lg={4} className={classes.customPadding}>                    
                                        <MyCard item={item}/>
                                    </GridItem>
                                    )):null
                                }
                            </GridContainer>
                        </div>
                    )
                },
                {
                    tabButton: "REGLAMENTOS",
                    tabIcon: BookOutlined,
                    tabContent: (
                        <div style={{marginTop:'-50px',overflowX:'hidden',overflowY:'hidden',padding:'40px',textAlign:'center'}}>
                            <GridContainer className={classes.justifyCenter}>                            
                                {
                                    !loading && data && data.data && data.data.data?data.data.data.map((item,index)=>(
                                    <GridItem key={`card${index}`} xs={12} sm={12} md={6} lg={4} className={classes.customPadding}>                    
                                        <MyCard item={item}/>
                                    </GridItem>
                                    )):null
                                }
                            </GridContainer>
                        </div>
                    )
                },                
                ]}
            />
            <GridContainer className={classes.justifyCenter}>
                {
                    loading?
                    Array.from(new Array(3)).map((item,index)=>(
                    <GridItem key={`card${index}`} xs={12} sm={12} md={6} lg={4} className={classes.customPadding}>                    
                        <MyCard loading/>
                    </GridItem>
                    )):null
                }
                <GridItem xs={12}>                    
                    {
                        error || (data && !data.status)?
                        <SnackbarContent
                            message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                            close
                            color="danger"
                            icon="info_outline"
                        />:null
                    }
                    {
                        data && data.status && data.data.data.length===0?
                        <SnackbarContent
                            message={<div><span>No hay documentos publicados de este tipo</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                            close
                            color="info"
                            icon="info_outline"
                        />:null
                    }
                </GridItem>            
            </GridContainer>
            <br/>
            <br/>
            <GridContainer className={classes.justifyCenter}>
              <GridItem xs={12}>
                <Pagination count={data && data.status?data.data.last_page:1} page={page} onChange={handleChange} />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
