import React from "react";
import useAxios from 'axios-hooks';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { container,title } from "assets/jss/material-kit-react.js";
import Carousel from "react-slick";
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from 'react-loading-skeleton';
import GLOBAL from 'global';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      minWidth: 300,
      width: '100%',
    },
    image: {
      position: 'relative',
      height: 200,
      [theme.breakpoints.down('xs')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
      },
      '&:hover, &$focusVisible': {
        zIndex: 1,
        '& $imageBackdrop': {
          opacity: 0.15,
        },
        '& $imageMarked': {
          opacity: 0,
        },
        '& $imageTitle': {
          border: '4px solid currentColor',
        },
      },
    },
    focusVisible: {},
    imageButton: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
    },
    imageSrc: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
      position: 'relative',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
      height: 3,
      width: 18,
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      bottom: -2,
      left: 'calc(50% - 9px)',
      transition: theme.transitions.create('opacity'),
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        ...container
    },
    section: {
        padding: "2px 0",
        marginTop: "-20px",
        textAlign: "center"
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign:'left'
    },
    description: {
        color: "#999"
    },
    justifyCenter: {
        justifyContent: "center !important"
    },
  }));

export default function PublicidadSection() {
  const classes = useStyles();
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/imagenesp/imagenesp`
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true
  };
  const downloadFile = (uri,nombre="archivo") => {
    let link = document.createElement("a");
    link.download = nombre;
    link.href = uri;   
    link.click();    
    link = null;
  }

  if (error || (data && !data.status)) 
            return (
                    // <SnackbarContent
                    //     message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span></div>}
                    //     close
                    //     color="danger"
                    //     icon="info_outline"
                    // />
                    <div></div>
                    );
  if (loading) return(
        <div className={classes.container}>
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}><Skeleton width='100%'/></h2>                    
                    </GridItem>
                </GridContainer>
                <div>
                    <GridContainer>                        
                        <GridItem xs={12} sm={12} md={4}>
                            <Skeleton width='100%' height='480px'/>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Skeleton width='100%' height='480px'/>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Skeleton width='100%' height='480px'/>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
  )

  return (
    <div>
        <div className={classes.container}>
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        <h2 className={classes.title}>PUBLICIDAD</h2>
                    </GridItem>
                </GridContainer>
                <Carousel {...settings}>
                    {
                        data.data?data.data.map((item,index) => (
                        <div key={`slide${index}`}>
                            <ButtonBase
                                focusRipple                                
                                className={classes.image}
                                focusVisibleClassName={classes.focusVisible}
                                style={{
                                    //width: image.width,
                                    width: "100%",
                                }}
                                onClick={()=>window.open(item.link, '_blank')}
                                >
                                <span
                                    className={classes.imageSrc}
                                    style={{
                                    backgroundImage: `url(${item.imagen})`,
                                    }}
                                />
                                <span className={classes.imageBackdrop} />
                                <span className={classes.imageButton}>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        color="inherit"
                                        className={classes.imageTitle}
                                        >
                                            Abrir
                                            <span className={classes.imageMarked} />
                                    </Typography>
                                </span>
                            </ButtonBase>
                        </div>
                        )):null
                    }
                </Carousel>                
            </div>
        </div>
    </div>
  );
}
