/*eslint-disable*/
import React from "react";
import useAxios from 'axios-hooks';
import { useParams } from "react-router-dom";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import GLOBAL from "global";

const useStyles = makeStyles(styles);

// Radio online
// ReduxToolkit
import { useSelector, useDispatch } from 'react-redux';
import { setAudio, setIsPlaying } from './../../redux/playerSlice';
// FontAwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRadio,faPause } from '@fortawesome/free-solid-svg-icons'

// Logo radio
import imagenReproduciendo from "assets/img/gifoficial.gif";

export default function ChildHeaderLinks(props) {
  const classes = useStyles();  
  const { child } = useParams();
  //const [masopc,setMasopc] = React.useState([]);

  // Radio ONLINE
 // Redux
  const audio = useSelector((state) => state.player.audio);
  const isPlaying = useSelector((state) => state.player.isPlaying);
  const dispatch = useDispatch();  
  
  const togglePlay = (url) => {
        if (audio && isPlaying) {
          // Pausar el audio
          audio.pause();
          dispatch(setIsPlaying(false));

        } else if (audio && !isPlaying) {
          // Reanudar el audio
          audio.play();
          dispatch(setIsPlaying(true));
        } else {
          // Reproducir nuevo audio
          const newAudio = new Audio(url);
          newAudio.play()
            .then(() => {
              dispatch(setAudio(newAudio));
              dispatch(setIsPlaying(true));
            })
            .catch((error) => {
              console.log('Error al reproducir el audio:', error);
            });
        }
  };
   
   // Estilos de boton
  const btnstyles = {
    btnequalizador: {
      padding: '5px 12px 5px 5px'
    },
  };

  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/unidad/menu/${child}`
  );
  
  const getOpciones = () => {
    let ops = [];
    if(!loading && data && data.status && data.data){      
      if(data.data.mostrarProgramas){ops.push(<Link to={`/unidad/${child}/programas`} className={classes.dropdownLink}>
      PROGRAMAS
    </Link>)}
      if(data.data.mostrarProyectos){ops.push(<Link to={`/unidad/${child}/proyectos`} className={classes.dropdownLink}>
      PROYECTOS
    </Link>)}
      if(data.data.mostrarDocumentos){ops.push(<Link to={`/unidad/${child}/documentos`} className={classes.dropdownLink}>
      DOCUMENTOS
    </Link>)}
      if(data.data.mostrarPublicaciones){ops.push(<Link to={`/unidad/${child}/publicaciones`} className={classes.dropdownLink}>
      PUBLICACIONES
    </Link>)}
      if(data.data.mostrarRendicion){ops.push(<Link to={`/unidad/${child}/rendicion-cuentas`} className={classes.dropdownLink}>
      RENDICIÓN DE CUENTAS
    </Link>)}
      if(data.data.mostrarDenuncias){ops.push(<Link to={`/unidad/${child}/denuncia`} className={classes.dropdownLink}>
      DENUNCIAS
    </Link>)}
      if(data.data.mostrarConvocatorias){ops.push(<Link to={`/unidad/${child}/convocatorias`} className={classes.dropdownLink}>
      CONVOCATORIAS
    </Link>)}
      if(data.data.mostrarPublicacionesCientificas){ops.push(<Link to={`/unidad/${child}/publicaciones-cientificas`} className={classes.dropdownLink}>
      PUBLICACIONES CIENTÍFICAS
    </Link>)}
      if(data.data.mostrarEstadisticas){ops.push(<Link to={`/unidad/${child}/estadisticas`} className={classes.dropdownLink}>
      ESTADÍSTICAS
      </Link>)}
      if(data.data.mostrarEventos){ops.push(<Link to={`/unidad/${child}/eventos`} className={classes.dropdownLink}>
      EVENTOS
    </Link>)}
      if(data.data.mostrarServiciosPublicos){ops.push(<Link to={`/unidad/${child}/servicios-publicos`} className={classes.dropdownLink}>
      SERVICIOS PÚBLICOS
    </Link>)}
      if(data.data.mostrarProductos){ops.push(<Link to={`/unidad/${child}/productos`} className={classes.dropdownLink}>
      PRODUCTOS
    </Link>)}
      if(data.data.mostrarUbicaciones){ops.push(<Link to={`/unidad/${child}/ubicaciones`} className={classes.dropdownLink}>
      UBICACIONES
    </Link>)}      
    }
    return ops;
  }

  const rutaPadre = () => {
    if(!loading && data && data.status && data.data){
      if(data.data.padreid === 1){
        return '/';
      }else{
        return '/unidad/'+data.data.padreid;
      }
    }else{
      return '/';
    }
  }  

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
      <Tooltip
          title={ data && data.status && data.data? `Regresar a ${data.data.padre}`:'Gobierno Autónomo Departamental de Cochabamba' }
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Link
            to={ rutaPadre }
            className={classes.navLink}
            style={{marginTop:'-10px'}}
          >
            <OpenInBrowserIcon fontSize="large" />
          </Link>
        </Tooltip>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Link to={`/unidad/${child}`} className={classes.navLink}>
          INICIO
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to={`/unidad/${child}/quienes-somos`} className={classes.navLink}>
          QUIENES SOMOS
        </Link>
      </ListItem>
      {
        data && data.status && data.data?
        ( data.data.tienehijos > 0? 
        <ListItem className={classes.listItem}>
          <Link to={`/unidad/${child}/dependencia`} className={classes.navLink}>
            DEPENDENCIAS
          </Link>
        </ListItem>:null) : null
      }
      <ListItem className={classes.listItem}>
        <Link to={`/unidad/${child}/noticias`} className={classes.navLink}>
          NOTICIAS
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to={`/unidad/${child}/multimedia`} className={classes.navLink}>
          MULTIMEDIA
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText='VER MÁS'
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          //buttonIcon={Apps}            
          dropdownList={data&& data.status && data.data?getOpciones():[]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to={`/unidad/${child}/contacto`} className={classes.navLink}>
          CONTACTO
        </Link>
      </ListItem>
      
      <ListItem className={classes.listItem}>        
        <Tooltip
          id="instagram-twitter"
          title="Siguenos en Twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href={data && data.status && data.data.linkTwitter?data.data.linkTwitter:'#'}
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Siguenos en Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href={data && data.status && data.data.linkFacebook?data.data.linkFacebook:'#'}
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Siguenos en Youtube"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href={data && data.status && data.data.linkYoutube?data.data.linkYoutube:'#'}
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-youtube"} />
          </Button>
        </Tooltip>
      </ListItem>

       {/* Menu reproductor de musica online */}
   <ListItem className={classes.listItem}>
          <Tooltip
            id="music-player"
            title={isPlaying ? "Detener radio online" : "Reproducir radio online"}
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              // className={classes.navLink}
              style={btnstyles.btnequalizador}
              // onClick={() => togglePlay("http://radios.istbolivia.com:8006/stream")}
              onClick={() => togglePlay("https://stream-relay-geo.ntslive.net/stream")}
              // onClick={() => togglePlay("http://stream.srg-ssr.ch/m/rsj/aacp_96")}
            >
              {isPlaying ? (
               <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={imagenReproduciendo}
                    alt="Equalizador"
                    style={{ width: '40px', height: 'auto' }}
                  />
                  {/* <i className={classes.socialIcons + " fas fa-pause"} /> */}
                  <FontAwesomeIcon icon={faPause} />
                </div>
                ) : (
                // <i className={" fa fa-radio"} />
                <FontAwesomeIcon icon={faRadio} style={{ paddingTop: '4px' }} />
                // <i className={classes.socialIcons + " fas fa-play"} />
              )}
            </Button>
          </Tooltip>
      </ListItem>
      {/* FIN Menu reproductor de musica online */}

    </List>
  );
}
