import { container, cardTitle, title } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const multimediaSectionStyle = {
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        ...container
    },
    section: {
        padding: "2px 0",
        marginTop: "-10px",
        textAlign: "center"
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign:"left",
        cursor:'pointer'
    },
    subtitle: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign:"center"        
    },
    ...imagesStyle,
    itemGrid: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    cardTitle,
    smallTitle: {
        color: "#6c757d"
    },
    description: {
        color: "#999"
    },
    justifyCenter: {
        justifyContent: "center !important"
    },
    socials: {
        marginTop: "0",
        width: "100%",
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
        color: "#999"
    },
    socials2: {
        marginTop: "0",
        marginLeft: "5px",
        marginRight: "5px",        
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
        color: "#999"
    },
    margin5: {
        margin: "5px"
    },
    customColContainer:{
        textAlign:'center',        
    },
    customImage:{    
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    imagengaleria:{
        maxHeight : "500px"
    },
    spanalign:{
        display:"flex"
    }
};

export default multimediaSectionStyle;
