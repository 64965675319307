import { cardTitle, title } from "assets/jss/material-kit-react.js";
//import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const cardNoticiaStyle = {  
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  description: {
    color: "#999"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  customSmallText:{
    color:'white'
  },
  customImage:{    
    height: 0,
    backgroundSize:"100% 100%",
    paddingTop: '56.25%', // 16:9
  }
  
};

export default cardNoticiaStyle;
