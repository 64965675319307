import React from "react";
import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
import parse from 'html-react-parser';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from 'components/CustomButtons/Button.js';
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import ChildHeaderLinks from "components/Header/ChildHeaderLinks";
import Parallax from "components/Parallax/Parallax.js";
import logoVertical from "assets/img/logo-horizontal.png";
import LinkRounded from "@material-ui/icons/LinkRounded";

import styles from "assets/jss/material-kit-react/views/gobiernoView/gobiernoViewStyle";

import GLOBAL from 'global';
import Skeleton from "react-loading-skeleton";
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
var elem = null;
export default function ChildQuienesSomosView(props) {
  const classes = useStyles();
  const { child } = useParams();
  const imageClasses = classNames(
    //classes.imgRoundedCircle,
    // classes.imgRounded,
    // classes.imgFluid,
    classes.imagenBiografia,
  );
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/unidad/datos/${child}`
  );
  const { ...rest } = props;
  const createMarkup = (htmlContent) =>{    
    return {__html: htmlContent};
  }
//   if (data) {
//     elem = localStorage.getItem('gobierno');
//     try {
//       elem = document.getElementById(elem);
//       if (elem) {
//         window.scrollTo(0, elem.offsetTop);
//       }
//     } catch (error) {
//       //setTimeout(()=>{window.scrollTo(0,elem.offsetTop);},1000);
//       console.log("BAD");
//     }

//   }
  React.useEffect(() => {
    console.log("*********** QuienesSomosView *************");
    window.scrollTo(0, 0);    
  }, []);
  
  return (
    <>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.img} alt="Logo" />}
        rightLinks={<ChildHeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small className={classes.customParalaxBg}>
        <h2 className={classes.title}>{data && data.data && data.data.nombre ? data.data.nombre : ''}</h2>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.customMargin}>
          <div className={classes.container}>
            {
              error?
              <SnackbarContent
                  message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span></div>}
                  close
                  color="danger"
                  icon="info_outline"
              />:null
            }

            {
              loading?
              <Skeleton width="200px" height="200px"/>
              :(data.data && data.data.nombreBiografia)?
              (<>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <Card plain>
                      <CardBody>
                        <h3 id="biografia" className={classes.title2}>AUTORIDAD</h3>
                        <GridItem xs={12} sm={12} md={6} className={classes.itemDivImagenBiografia}>
                          {
                            loading?
                            <Skeleton width="200px" height="200px"/>:
                            <img 
                              src={data.data && data.data.imagenBiografia?data.data.imagenBiografia:null} 
                              alt="..." 
                              className={imageClasses}
                              onClick={()=> window.open(`${data.data && data.data.imagenBiografia?data.data.imagenBiografia:'...'}`, "_blank")} 
                            />
                          }
                        </GridItem>
                        <h4 className={classes.cardTitle} style={{ textAlign: 'center' }}>              
                            {
                              loading?
                              <Skeleton/>:
                              <>
                                {data.data && data.data.nombreBiografia?data.data.nombreBiografia:null}<br/>
                                <small className={classes.description}>{data.data && data.data.profesionBiografia?data.data.profesionBiografia:null}</small>
                              </>
                            }
                          </h4>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <br/>
                    <br/>
                    <h3 className={classes.title2}>BIOGRAFÍA</h3>
                    <div>
                      <p>
                        {
                          loading?
                          <Skeleton/>:
                          // data.data && data.data.reseniaBiografia?data.data.reseniaBiografia:null
                          <div dangerouslySetInnerHTML={createMarkup( (data.data && data.data.reseniaBiografia)?data.data.reseniaBiografia:'')}></div>
                        }
                      </p>
                    </div>
                  </GridItem>
                </GridContainer>
              </>):null
            }

            

            
                  
            <GridContainer>
              <GridItem xs={12}>

                   {
                      loading?
                      <div  className={classes.description}><Skeleton/> </div>: 
                      (data.data && data.data.mision)?
                      (<div><h3 className={classes.title2}> MISIÓN </h3>
                        <div className={classes.description}>
                            <p>
                              { data.data.mision }
                            </p>
                        </div></div>):null
                   }
                   {
                        loading? <Skeleton/>:
                        (data.data && data.data.vision)? (<div>
                        <h3 className={classes.title2}>VISIÓN</h3>
                        <div className={classes.description}>
                            <p>
                              { data.data.vision }
                            </p>
                        </div></div>):null                     
                   }
                  <h3 className={classes.title2}>OBJETIVOS</h3>
                  <div className={classes.description}>                      
                      <p>
                        {
                          loading?
                          <Skeleton/>:
                          data.data && data.data.objetivo?data.data.objetivo:null
                        }
                      </p>
                  </div>
                  <h3 className={classes.title2}>DESCRIPCIÓN</h3>
                  <div className={classes.description}>                      
                      <p>
                        {
                          loading?
                          <Skeleton/>:
                          data.data && data.data.descripcion?data.data.descripcion:null
                        }
                      </p>
                  </div>                  
              </GridItem>
            </GridContainer>
            <GridContainer>                        
              <GridItem xs={12}>
                <h3 id="organigrama" className={classes.title2}>ORGANIGRAMA</h3>                            
              </GridItem>
            </GridContainer>
            <div style={{textAlign:'center',paddingBottom:'50px'}}>
                {
                  loading?
                  <Skeleton/>:
                  data.data && data.data.organigrama?
                  parse(data.data.organigrama):null
                }                
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </>
  );
}
