import React from "react";
import {withRouter,useParams} from "react-router-dom";
import useAxios from 'axios-hooks';
import moment from "moment";
import 'moment/locale/es';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import CardMedia from '@material-ui/core/CardMedia';
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem";

import ReactPlayer from 'react-player/youtube';
import Slider from "react-slick";

//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from 'react-loading-skeleton';
import GLOBAL from 'global';

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";

import { cardTitle,cardSubtitle,cardHeader } from "assets/jss/material-kit-react.js";
import Helpers from "helpers";


const styles = {
  ...myStyles,
  ...imagesStyles,
  cardTitle,
  cardSubtitle,
  cardHeader:{
    ...cardHeader,
    margin: "0px 15px 0", 
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    marginLeft: '10%',
    marginRight: '10%',
  },
  pdata:{
    whiteSpace: 'pre-line'
  },
  customCaption:{
    border: '0.5px solid #e5e5e5'
  }
};
moment.locale('es');
const useStyles = makeStyles(styles);

const NoticiaDetalleComponent = (props) => {    
  const { id } = useParams();
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/noticia/ver/${id}`
  );
  const classes = useStyles();  
  const goToDetail = () => {

  }

    const createMarkup = (htmlContent) =>{
      
      return {__html: htmlContent};
    }

    if(error || (data && !data.status)) return (
      <SnackbarContent
          message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple onclick={()=>refetch}><i className={" fab fa-refresh"}/></Button></div>}
          close
          color="danger"
          icon="info_outline"
      />)    
    if(loading) return (
      <Card>
          <CardHeader className={classes.cardHeader}>
              <h3 className={classes.cardTitle}><Skeleton/></h3>
              <h6 className={classes.cardSubtitle}><Skeleton/></h6>
          </CardHeader>
          <Skeleton height="480px"/>
          <CardBody>
            <Skeleton height="480px"/>
            <p><Skeleton/></p>
            <Skeleton height="480px"/>  
          </CardBody>
          <CardFooter>
            <Skeleton width="150px"/>
            <Skeleton width="150px"/>
          </CardFooter>
      </Card>
    )
    let cantidad = data.data && data.data.galeria && data.data.galeria.length<2?1:2;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: cantidad,
      slidesToScroll: cantidad,
      autoplay: true,
      adaptativeHeight: true,
      centerMode: true,
      centerPadding: cantidad<2?'200px':'1px'
    };

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <h3 className={classes.cardTitle}>{data.data?data.data.titulo:''}</h3>
                <h6 className={classes.cardSubtitle}>PUBLICADO EL {data.data?moment(data.data.fecha).format('LL'):''}</h6>
            </CardHeader>            
            <CardMedia
                className={classes.media}
                image={data.data?data.data.imagen:'...'}
                title={data.data?data.data.titulo:''}
                onClick={()=> window.open(`${data.data?data.data.imagen:'...'}`, "_blank")}
            />
            <hr/>
            {
              data.data && data.data.video && String(data.data.video).trim().length>0?
              <ReactPlayer url={data && data.data?data.data.video:''} width="100%"/>:null
            }
            <CardBody>                
                {/* <p className={classes.pdata}>{data.data?data.data.contenido:''}</p> */}
                <div dangerouslySetInnerHTML={createMarkup(data.data?data.data.contenido:'')}></div>
            </CardBody>
            {
              data.data && data.data.galeria && data.data.galeria.length>0?
              <Slider {...settings}>
                {
                  data && data.data?data.data.galeria.map((item,index)=>(
                    <div key={`item${index}`} className={classes.customCaption}>
                      <img src={item.imagen} alt={item.titulo} style={{cursor:'pointer'}} className="slick-image" onClick={()=> window.open(`${item.imagen}`, "_blank")} />
                      <p className="slick-caption" style={{textShadow:'2px 2px 5px #000000'}}>{item.titulo}</p>
                    </div>
                  )):null                  
                }
              </Slider>:null
            }
            <br/>
            <CardFooter style={{padding: 0,marginTop: '50px'}}>
              <GridItem md>
                <Button color="facebook" round round onClick={()=>Helpers.shareFacebook(window.location.href)}>
                  <i className={"fab fa-facebook-square"}/>{" "}Compartir en Facebook
                </Button>
                <Button color="twitter" round onClick={()=>Helpers.shareTwitter(window.location.href,data && data.status && data.data?data.data.titulo:'')}>
                  <i className={"fab fa-twitter"}/>{" "}Compartir en Twitter
                </Button>
                <Button style={{backgroundColor:'#07bc4c',color:'white'}} round onClick={()=>Helpers.shareWhatsapp(window.location.href)}>
                  <i className={"fab fa-whatsapp"}/>{" "}Compartir por Whatssapp
                </Button>
              </GridItem>
            </CardFooter>
        </Card>
    );
}

export default withRouter(NoticiaDetalleComponent);