import React from "react";
import { withRouter } from "react-router-dom";
//import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import HeaderLinks from "components/Header/myHeaderLinks.js";
import Card from "components/Card/Card";
import Parallax from "components/Parallax/Parallax.js";

//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import GLOBAL from 'global';
// styles
import { container, title } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";

import logoVertical from "assets/img/logo-horizontal.png";
import Skeleton from "react-loading-skeleton";
const dashboardRoutes = [];

const styles = {
  container,
  title:{
    ...title,
    color:'white',
    textAlign:'center',
    width:'100%'
  },
  title2: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign:'center'
},
  question:{
    ...title,
    color:'black',
    textAlign:'left',
    width:'100%'
  },
  answer:{
    ...title,    
    textAlign:'left',
    width:'100%'
  },
  ...myStyles,
  justifyCenter: {
    justifyContent: "center !important"
  },
  customPadding:{
    paddingTop:'10px',
    paddingBottom:'10px',
  }
}

const useStyles = makeStyles(styles);

const PreguntasFrecuentesView = (props) => {
  const classes = useStyles();  
  const { ...rest } = props;  
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/serviciodepartamental/servicios`
  ); 

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []); 
  
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small className={classes.customParalaxBg}>
          <h1 className={classes.title}>SERVICIOS DEPARTAMENTALES</h1>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <GridContainer className={classes.justifyCenter}>
              {
                error || (data && !data.status)?
                <SnackbarContent
                    message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                    close
                    color="danger"
                    icon="info_outline"
                />:null
              }
              {
                data && data.status && data.data.length===0?
                <SnackbarContent
                    message={<div><span>No hay datos publicados</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                    close
                    color="info"
                    icon="info_outline"
                />:null
              }
              {
                loading?
                Array.from(new Array(3)).map((item,index)=>(
                  <GridItem key={`card${index}`} xs={12} sm={4}>
                    <Skeleton height='320px'/>
                  </GridItem>
                )):null
              }
              {
                data && data.data?data.data.map((item,index)=>(
                  <GridItem key={`card${index}`} xs={12} sm={6} md={4} className={classes.customPadding}>
                    <Card style={{maxWidth: "20rem",height:'100%',cursor:'pointer'}} onClick={()=>props.history.push(`/unidad/${item.undId}`)}>
                        <img src={item.imagenIcono} alt={item.nombre} width="100%"/>
                        <h3 className={classes.title2}>{item.nombre}</h3>                        
                    </Card>
                  </GridItem>
                )):null
              }              
            </GridContainer>            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default withRouter(PreguntasFrecuentesView);