import { container,title } from "assets/jss/material-kit-react.js";

const multiSectionStyle = {
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        ...container
    },
    section: {
        padding: "1px 0",
        textAlign: "center"
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "-5px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign:'left'
    },
    description: {
        color: "#999"
    },
    justifyCenter: {
        justifyContent: "center !important"
    },
    customCard:{
        height:'90%'
    }
};

export default multiSectionStyle;
