import React from "react";
import useAxios from 'axios-hooks';
import { withRouter } from 'react-router-dom';
// @material-ui/core components
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Button from 'components/CustomButtons/Button.js';
// @material-ui/icons
import FontDownload from "@material-ui/icons/CloudDownload";
import Tooltip from "@material-ui/core/Tooltip";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";

import styles from "assets/jss/material-kit-react/views/inicioView/ultimasLeyesDecretosSectionStyle";
import decreto from "assets/img/decreto.jpg";
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from 'react-loading-skeleton';
import GLOBAL from 'global';

const useStyles = makeStyles(styles);

const UltimasLeyesDecretosSection = (props) => {
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/inicio/getLeyesDecretosNuevo`
  );
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );  

  const downloadFile = (uri,nombre="archivo") => {
    let link = document.createElement("a");
    link.download = nombre;
    link.href = uri;   
    link.click();    
    link = null;
  }
  if (error) return <SnackbarContent
                        message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                        close
                        color="danger"
                        icon="info_outline"
                    />
  if (loading) return(
    <div className={classes.container}>
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                <h2 className={classes.title}><Skeleton/></h2>
                <h5 className={classes.description}>
                  <Skeleton/>
                </h5>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer>                    
                  <GridItem xs={12} sm={12} md={4}>
                    <Skeleton width='100%' height='320px'/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Skeleton width='100%' height='320px'/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Skeleton width='100%' height='320px'/>
                  </GridItem>
                </GridContainer>
                
            </div>
        </div>
    </div>
  )

  return (
    <div>
        <div className={classes.container}>
            <div className={classes.section}>
                {
                    (data.data.leyes.length>0 || data.data.decretos.length > 0)
                    ?(
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <h2 className={classes.title} onClick={()=>props.history.push("/leyes-decretos")}>
                                    <Tooltip 
                                        id="tooltip-top"
                                        title="HAGA CLICK PARA IR A LA SECCIÓN DE LEYES Y DECRETOS"
                                        placement="top"              
                                    >
                                        <span>ÚLTIMAS LEYES Y DECRETOS DEPARTAMENTALES</span>
                                    </Tooltip>
                                </h2>
                            </GridItem>
                        </GridContainer>
                    ):null
                }
                {
                    data.data.leyes.length>0
                    ?(
                        <h3 className={classes.subtitle}>LEYES</h3>
                    ):null
                }
                <GridContainer className={classes.justifyCenter}>
                  {
                      data.data? 
                      data.data.leyes.map((item,index)=>(
                          <GridItem key={`card${index}`} xs={12} sm={12} md={4}>
                              <Card className={classes.customCard}>
                                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                                      <img src={decreto} alt={item.titulo} className={imageClasses} />
                                  </GridItem>
                                  <h4 className={classes.cardTitle}>
                                      {item.titulo}
                                      <br />
                                      <small className={classes.smallTitle}>{item.tipo}</small>
                                      <small className={classes.smallTitle}>&nbsp;{item.fechaPromulgacion}</small>
                                  </h4>
                                  <CardBody>
                                      <p className={classes.description}>{item.resumen}</p>
                                  </CardBody>
                                  <CardFooter className={classes.justifyCenter}>
                                      <Button                                                
                                          color="primary"
                                          className={classes.margin5}
                                          onClick={()=>window.open(item.archivo, '_blank')}
                                          >
                                              <FontDownload/>{' '}Descargar
                                      </Button>
                                  </CardFooter>
                              </Card>
                          </GridItem>
                      )):null                      
                  }
                </GridContainer>
                {
                    data.data.decretos.length>0
                    ?(<h3 className={classes.subtitle}>DECRETOS</h3>)
                    :null
                }
                <GridContainer className={classes.justifyCenter}>
                    {
                        data.data?
                        data.data.decretos.map((item,index)=>(
                            <GridItem key={`card${index}`} xs={12} sm={12} md={4}>
                                <Card className={classes.customCard}>
                                    <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                                        <img src={decreto} alt={item.titulo} className={imageClasses} />
                                    </GridItem>
                                    <h4 className={classes.cardTitle}>
                                        {item.titulo}
                                        <br />
                                        <small className={classes.smallTitle}>{item.tipo}</small>
                                        <small className={classes.smallTitle}>&nbsp;{item.fechaPromulgacion}</small>
                                    </h4>
                                    <CardBody>
                                        <p className={classes.description}>{item.resumen}</p>
                                    </CardBody>
                                    <CardFooter className={classes.justifyCenter}>
                                        <Button                                                
                                            color="primary"
                                            className={classes.margin5}
                                            onClick={()=>window.open(item.archivo, '_blank')}
                                            >
                                                <FontDownload/>{' '}Descargar
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        )):null                        
                    }
                </GridContainer>
                    
                
            </div>
        </div>
    </div>
  );
}

export default withRouter(UltimasLeyesDecretosSection);