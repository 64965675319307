/*eslint-disable*/
import React,{ useState,useEffect }  from "react";
import useAxios from 'axios-hooks';
// react components for routing our app without refresh
import { Link,useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Home,Apps, Business, PlayArrow, Book, Clear, Contacts, PlayArrowRounded, Radio } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import GLOBAL from "global";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

// ReduxToolkit
import { useSelector, useDispatch } from 'react-redux';
import { setAudio, setIsPlaying } from './../../redux/playerSlice';

// Logo radio
import imagenReproduciendo from "assets/img/gifoficial.gif";

// FontAwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRadio,faPause } from '@fortawesome/free-solid-svg-icons'

export default function HeaderLinks(props) {
  const classes = useStyles();

  // Redux
  const audio = useSelector((state) => state.player.audio);
  const isPlaying = useSelector((state) => state.player.isPlaying);
  const dispatch = useDispatch();


  // png radio
  const togglePlay = (url) => {
        if (audio && isPlaying) {
          // Pausar el audio
          audio.pause();
          dispatch(setIsPlaying(false));

        } else if (audio && !isPlaying) {
          // Reanudar el audio
          audio.play();
          dispatch(setIsPlaying(true));
        } else {
          // Reproducir nuevo audio
          const newAudio = new Audio(url);
          newAudio.play()
            .then(() => {
              dispatch(setAudio(newAudio));
              dispatch(setIsPlaying(true));
            })
            .catch((error) => {
              console.log('Error al reproducir el audio:', error);
            });
        }
  };

  // Estilos de boton
  const btnstyles = {
    btnequalizador: {
      padding: '5px 12px 5px 5px'
    },
  };

  let {id} = useParams();
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/inicio/getMenuEspecial`
  );
  const goSomeWhere = (path) => {
      //console.log("ENTRE A GOSOMEWHERE",path);
      localStorage.setItem('gobierno',path);
      //console.log("LOCALSTORAGE",localStorage.getItem('gobierno'));
  }
  const goSomeWhereInicio = (path) => {
    //console.log("ENTRE A goSomeWhereInicio",path);
    localStorage.setItem('inicio',path);
    //console.log("LOCALSTORAGE",localStorage.getItem('inicio'));
  }
  //console.log("HeaderLinks Called param.id=",id);
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.navLink} onClick={()=>localStorage.clear()}>
          <div style={{textAlign:'center'}}>            
            INICIO
          </div>            
        </Link>
      </ListItem>

{/* Redireccionando a Portal ciudadano */}
     <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            href={'http://portal-ciudadano.gobernaciondecochabamba.bo/'}
            target="_blank"
            className={classes.navLink}
          >
            PORTAL CIUDADANO
          </Button>
      </ListItem>
{/* FIN Redireccionando a Portal ciudadano */}

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText='GOBIERNO'
          buttonProps={{
            className: classes.navLink,                
            color: "transparent"
          }}
          //buttonIcon={Apps}            
          dropdownList={[
            <Link to="/gobierno" className={classes.dropdownLink} onClick={()=>goSomeWhere("biografia")}>
              BIOGRAFÍA
            </Link>,
            <Link to="/gobierno" className={classes.dropdownLink} onClick={()=>goSomeWhere("secretarias")}>
              SECRETARÍAS
            </Link>,            
            <Link to="/gobierno" className={classes.dropdownLink} onClick={()=>goSomeWhere("organigrama")}>
              ORGANIGRAMA
            </Link>,
            // <Link to="/unidad/1/dependencia" className={classes.dropdownLink}>
            //   APOYO DIRECTO
            // </Link>
            <Link to="/apoyo-directo" className={classes.dropdownLink}>
              APOYO DIRECTO
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/servicios" className={classes.navLink}>
          <div style={{textAlign:'center'}}>            
              SERVICIOS
          </div>            
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/noticias" className={classes.navLink}>
          <div style={{textAlign:'center'}}>            
              NOTICIAS
          </div>            
        </Link>
      </ListItem>

      {/* Menu modificado por davidSP-UGE */}
        <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText='MULTIMEDIA'
          buttonProps={{
            className: classes.navLink,                
            color: "transparent"
          }}
          //buttonIcon={Apps}            
          dropdownList={[
            <Link to="/multimedia" className={classes.dropdownLink}>
              MULTIMEDIA
            </Link>,
            <Link to="/radio" className={classes.dropdownLink}>
              RADIO EN LINEA
            </Link>,            
          ]}
        />
      </ListItem>
      {/* FIN Menu modificado por davidSP */}

      {
        data && data.status && data.data.idPlanificacion?
          <ListItem className={classes.listItem}>
            <Link to={`/leyes-decretos`} className={classes.navLink}>
              <div style={{textAlign:'center'}}>
                GACETA
              </div>
            </Link>
          </ListItem>:null
      }
      
      <ListItem className={classes.listItem}>            
        <CustomDropdown
          noLiPadding
          buttonText='TRANSPARENCIA'
          buttonProps={{
            className: classes.navLink,                
            color: "transparent"
          }}
          //buttonIcon={Apps}            
          dropdownList={[
            <Link to="/denuncia" className={classes.dropdownLink}>
              DENUNCIA
            </Link>,
            <Link to="/sugerencia" className={classes.dropdownLink}>
              SUGERENCIA
            </Link>,
            <Link to={data && data.status && data.data.idPlanificacion?`/unidad/${data.data.idPlanificacion}/rendicion-cuentas`:'/not-found'} className={classes.dropdownLink}>
              RENDICIÓN DE CUENTAS
            </Link>,
            <Link to={data && data.status && data.data.idContratacion?`/unidad/${data.data.idContratacion}/convocatorias`:'/not-found'} className={classes.dropdownLink}>
              CONVOCATORIAS
            </Link>,
             <Link to={data && data.status && data.data.idContratacion?`/unidad/${data.data.idContratacion}/auditoria`:'/not-found'} className={classes.dropdownLink}>
              AUDITORIAS
            </Link>,
          ]}
        />          
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/contacto" className={classes.navLink}>
          <div style={{textAlign:'center'}}>            
            CONTACTO
          </div>            
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>        
        <Tooltip
          id="instagram-twitter"
          title="Siguenos en Twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href={data && data.status && data.data.linkTwitter?data.data.linkTwitter:'#'}
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Siguenos en Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href={data && data.status && data.data.linkFacebook?data.data.linkFacebook:'#'}
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Siguenos en Youtube"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href={data && data.status && data.data.linkYoutube?data.data.linkYoutube:'#'}
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-youtube"} />
          </Button>
        </Tooltip>
      </ListItem>
      {/* Menu reproductor de musica online */}
      <ListItem className={classes.listItem}>
          <Tooltip
            id="music-player"
            title={isPlaying ? "Detener radio en linea" : "Reproducir radio online"}
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              // className={classes.navLink}
              style={btnstyles.btnequalizador}
              onClick={() => togglePlay("https://live.turadiotv.com/8110/stream")} // Radio GOBERNACIÓN en el menu # 2
              // onClick={() => togglePlay("http://radios.istbolivia.com:8006/stream")}
              // onClick={() => togglePlay("https://stream-relay-geo.ntslive.net/stream")}
              // onClick={() => togglePlay("http://stream.srg-ssr.ch/m/rsj/aacp_96")}
            >
              {isPlaying ? (
               <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={imagenReproduciendo}
                    alt="Equalizador"
                    style={{ width: '40px', height: 'auto' }}
                  />
                  <FontAwesomeIcon icon={faPause} />
                </div>
                ) : (
                <FontAwesomeIcon icon={faRadio} style={{ paddingTop: '4px' }} />
              )}
            </Button>
          </Tooltip>
      </ListItem>
      {/* FIN Menu reproductor de musica online */}

    </List>
  );
}
