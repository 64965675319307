import React from "react";
import { useParams } from "react-router-dom";
//import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Card from "components/Card/Card";
import CardHeader from 'components/Card/CardHeader';
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";

import HeaderLinks from "components/Header/ChildHeaderLinks";

// core components
import Parallax from "components/Parallax/Parallax.js";
import CardMedia from '@material-ui/core/CardMedia';
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from "react-loading-skeleton";
import GLOBAL from 'global';

// styles
import { container, title,  } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";

import logoVertical from "assets/img/logo-horizontal.png";
import Helpers from "helpers";

const dashboardRoutes = [];

const styles = {
  container,
  title:{
    ...title,
    color:'white',
    textAlign:'center',
    width:'100%'
  },
  titleData:{
    ...title,    
    textAlign:'center',
    width:'100%',
    borderRadius: '25px',
    background:'#8fc9e8'
  }, 
  ...myStyles,
  justifyCenter: {
    justifyContent: "center !important"
  },  
  inputRootCustomClasses: {
    margin: "0!important",        
  },
  formControl: {
    margin: "0 !important",
    paddingTop: "0",
    width:"80%"
  },
  searchIcon: {
    width: "20px",
    height: "20px",
    color: "inherit"
  },
  dividerStyle:{
    border:'solid 1px #e9e9e9'
  },
  customImage:{    
    height: 0,
    paddingTop: '56.25%', // 16:9
    marginLeft: '17%',
    marginRight: '17%',
},
}

const useStyles = makeStyles(styles);

export default function ChildConvocatoriaDetalleView(props) {
    const classes = useStyles();
    const { id } = useParams();
    const { ...rest } = props;
    const [{ data, loading, error }, refetch] = useAxios(
        `${GLOBAL.apiURL}/api/unidad/datosConvocatoria/${id}`
    );
    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []); 

    const createMarkup = (htmlContent) =>{    
        return {__html: htmlContent};
    }
  
    return (
        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                height: 400,
                color: "primary"
                }}
                {...rest}
            />
            <Parallax small className={classes.customParalaxBg}>
                <h1 className={classes.title}>CONVOCATORIAS<br/><small style={{color:'white'}}>{data && data.status?String(data.message).toUpperCase():''}</small></h1>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>            
                    <div className={classes.section}>                        
                        <GridContainer>
                            <GridItem xs={12}>
                                {
                                    !loading && error || (data && !data.status)?
                                    <SnackbarContent
                                        message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                                        close
                                        color="danger"
                                        icon="info_outline"
                                    />:null
                                }                                      
                            </GridItem>              
                        </GridContainer>                   
                        <GridContainer justify='center'>
                            {
                                loading?
                                <GridItem xs={12}>
                                    <Skeleton height='320px'/>
                                </GridItem>:null
                            }
                            {
                                !loading && data && data.status && data.data?
                                <GridItem xs={12} sm={12}>
                                    <Card>
                                        <CardHeader>
                                            <h4 className={classes.titleData}>{String(data.data.titulo).toUpperCase()}</h4>
                                        </CardHeader>                                        
                                        <CardMedia
                                            className={classes.customImage}
                                            image={data.data.imagen}
                                        />
                                        <CardBody>
                                            {/* <p>{data.data.contenido}</p> */}
                                            <div dangerouslySetInnerHTML={createMarkup(data.data?data.data.contenido:'')}></div>
                                            <Button color="primary" onClick={()=>window.open(data.data.archivo,"_blank")}>Descargar archivo</Button>
                                        </CardBody>
                                        <CardFooter style={{padding: 0,marginTop: '50px'}}>
                                            <GridItem md>
                                                <Button color="facebook" round round onClick={()=>Helpers.shareFacebook(window.location.href)}>
                                                    <i className={"fab fa-facebook-square"}/>{" "}Compartir en Facebook
                                                </Button>
                                                <Button color="twitter" round onClick={()=>Helpers.shareTwitter(window.location.href,data && data.status && data.data?data.data.titulo:'')}>
                                                    <i className={"fab fa-twitter"}/>{" "}Compartir en Twitter
                                                </Button>
                                                <Button style={{backgroundColor:'#07bc4c',color:'white'}} round onClick={()=>Helpers.shareWhatsapp(window.location.href)}>
                                                    <i className={"fab fa-whatsapp"}/>{" "}Compartir por Whatssapp
                                                </Button>
                                            </GridItem>
                                        </CardFooter>
                                    </Card>
                                </GridItem>:null
                            }
                        </GridContainer>
                    </div>        
                </div>
            <Footer />
            </div>
        </div>
    );
}
