import React, { createContext, useState } from "react";

export const MusicPlayerContext = createContext();

export const MusicPlayerProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <MusicPlayerContext.Provider value={{ isPlaying, setIsPlaying }}>
      {children}
    </MusicPlayerContext.Provider>
  );
};