import React from "react";
import moment from "moment";
import 'moment/locale/es';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import CardMedia from '@material-ui/core/CardMedia';
import Button from "components/CustomButtons/Button.js";

import Skeleton from 'react-loading-skeleton';

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

import { cardTitle,cardSubtitle,cardHeader } from "assets/jss/material-kit-react.js";

const styles = {
  ...imagesStyles,
  cardTitle,
  cardSubtitle,
  cardHeader:{
    ...cardHeader,
    margin: "0px 15px 0", 
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
};
moment.locale('es');
const useStyles = makeStyles(styles);

const CardNoticiaPreview = (props) => {
    const {loading=false,data=null} = props;
    const classes = useStyles();
    
    const goToDetail = () => {
        //console.log("goToDetail=",props.data.slug);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        props.history.push(`/noticia-detalle/${props.data.slug}`);
    }

    if(loading) return (
        <Card>
            <CardHeader className={classes.cardHeader}>                
                <h3 className={classes.cardTitle}><Skeleton/></h3>
                <h6 className={classes.cardSubtitle}><Skeleton/></h6>
            </CardHeader>
            <Skeleton height="450px"/>
            <CardBody>
                <h4 className={classes.cardTitle}><Skeleton/></h4>
                <p><Skeleton/></p>                
            </CardBody>
            <CardFooter justify="center">
                <Skeleton width="250px"/>
            </CardFooter>
        </Card>
    );
    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <h3 className={classes.cardTitle}>{data?String(data.titulo).toUpperCase():''}</h3>
                <h6 className={classes.cardSubtitle}>PUBLICADO EL {data?moment(data.fecha).format('LL') :''}</h6>
            </CardHeader>            
            <CardMedia
                className={classes.media}
                image={data?data.imagen:'...'}
                title="portada"
            />
            <CardBody>
                {
                    data && data.antetitulo && String(data.antetitulo).trim().length>0?
                    <h4 className={classes.cardTitle}>{String(data.antetitulo).toUpperCase()}</h4>:null
                }                
                <p>{data?data.resumen:''}</p>
            </CardBody>
            <CardFooter>
                <Button color="primary" onClick={()=>props.onHandleClick()}>Ver más</Button>
            </CardFooter>
        </Card>
    );
}

export default CardNoticiaPreview;