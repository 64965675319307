import React from "react";
import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import ChildHeaderLinks from "components/Header/ChildHeaderLinks";

import Parallax from "components/Parallax/Parallax.js";

//ALERT
import GLOBAL from 'global';

// styles
import { container, title } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";

import logoVertical from "assets/img/logo-horizontal.png";

import ChildBannerSection from "./Sections/ChildBannerSection";
import ChildNoticiaSection from "./Sections/ChildNoticiasSection";
import ChildMultimediaSection from "./Sections/ChildMultimediaSection";
import PublicidadSection from "../Inicio/Sections/PublicidadSection";
import MultiSection from "../Inicio/Sections/MultiSection";
import ChildGuiaTramitesSection from "./Sections/ChildGuiaTramitesSection";

const dashboardRoutes = [];

const styles = {
  container,
  title:{
    ...title,
    color:'white',
    textAlign:'center',
    width:'100%'
  },
  question:{
    ...title,
    color:'black',
    textAlign:'left',
    width:'100%'
  },
  answer:{
    ...title,    
    textAlign:'left',
    width:'100%'
  },
  ...myStyles,
  justifyCenter: {
    justifyContent: "center !important"
  },
  customPadding:{
    paddingTop:'10px',
    paddingBottom:'10px',
  }
}

const useStyles = makeStyles(styles);

export default function SecretariaView(props) {
  const classes = useStyles();  
  const { ...rest } = props;  
  const { id } = useParams();
  // const [{ data, loading, error }, refetch] = useAxios(
  //   `${GLOBAL.apiURL}/api/unidad/datos/${id}`
  // ); 

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []); 
  
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
        rightLinks={<ChildHeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <ChildBannerSection/>
      {/* {
        false?
        <Parallax small className={classes.customParalaxBg}>
            <h2 className={classes.title}>{data && data.status && data.data?data.data.nombre:''}</h2>
        </Parallax>:null
      } */}
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div style={{height:'5px'}}></div>        
        <ChildNoticiaSection/>
        <ChildMultimediaSection/>
        <PublicidadSection/>        
        <MultiSection/>
        <ChildGuiaTramitesSection/>
        <br/>
        <br/>
        <br/>
      </div>
      <Footer />
    </div>
  );
}
