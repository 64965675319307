const GLOBAL = {
    //*********** DEPLOY ***********
    //apiURL:'https://gadc.serconsig-soft.com',
    //assetsURL:'',
    apiURL: 'https://gobernaciondecochabamba.bo',
    assetsURL: '',
    //********* DEV **********
    //apiURL:'http://192.168.0.107/gobernacion/public',
    //assetsURL:'',
    // apiURL:'http://172.16.32.105/gobernacion/public',
    // assetsURL:'',

    // apiURL:'http://127.0.0.1:8000',
    // assetsURL:'',
}
export default GLOBAL;