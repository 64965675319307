import React from "react";
import {withRouter} from "react-router-dom";
import useAxios from 'axios-hooks';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Badge from 'components/Badge/Badge.js';
import Search from "@material-ui/icons/Search";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from 'react-loading-skeleton';
import GLOBAL from 'global';

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { cardTitle,cardSubtitle,cardHeader } from "assets/jss/material-kit-react.js";

const styles = theme => ({
    ...headerLinksStyle(theme),
    ...imagesStyles,
    cardTitle:{
        ...cardTitle,
        color:'white'
    },
    cardSubtitle,
    cardHeader,
    customButton:{
        width:'100%',
        color:'#000000',
        textAlign:'left'
    },
    customBadge:{
        cursor:'pointer'
    },
    inputRootCustomClasses: {
        margin: "0!important",        
    },
    formControl: {
        margin: "0 !important",
        paddingTop: "0",
        width:"80%"
    },
    searchIcon: {
        width: "20px",
        height: "20px",
        color: "inherit"
    },
    customCategoryStyle:{
        cursor:'pointer',        
        margin:'0px 0px',
        padding:'2px 2px',
        fontWeight:'200'
    },
    customCategorySelectedStyle:{
        cursor:'pointer',
        backgroundColor:'rgb(0,127,198)',
        color:'#fff',
        margin:'0px 0px',
        padding:'10px 10px',
        fontWeight:'bold'
    },
    customBorderStyle:{
        margin:'0px',
        borderColor:'rgba(0, 0, 0, 0.12)'
    }
})

const useStyles = makeStyles(styles);

const CardSidebarComponent = (props) => {    
    //const {loading=false} = props;
    const classes = useStyles();
    const [{ data, loading, error }, refetch] = useAxios(
        `${GLOBAL.apiURL}/api/noticia/getCategoriasPalabrasClave`
      );
    const [selectedCategory,setSelectedCategory] = React.useState('');
    const [selectedTag,setSelectedTag] = React.useState('');
    const [search,setSearch] = React.useState('');
    const [finding,setFinding] = React.useState(false);

    const onChangeCategoria = (mycategory) => {        
        if(selectedCategory === mycategory){
            setSelectedCategory('');
            props.onChangeCategoria('');
        }else{
            setSelectedCategory(mycategory);
            props.onChangeCategoria(mycategory);
        }        
    }
    const onChangeTag = (mytag) => {
        if(selectedTag === mytag){
            setSelectedTag('');
            props.onChangeTag('');
        }else{
            setSelectedTag(mytag);
            props.onChangeTag(mytag);
        }        
    }
    const onHandleChange = (event) => {        
        setSearch(event.target.value);
    }
    const onHandleClick = () => {
        if(finding){
            setFinding(!finding);
            setSearch('');
            props.onChangeSearch('');
        }else{
            setFinding(!finding);
            props.onChangeSearch(search);
            //console.log("enviara search=",search);
        }
    }

    if (error) return (
        <>
            <Card>
                <CardHeader color="info" className={classes.cardHeader}>
                    <h4 className={classes.cardTitle}>CATEGORÍA</h4>
                </CardHeader>
                <CardBody>
                    <SnackbarContent
                        message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple onClick={()=>refetch}><i className={" fab fa-refresh"}/> Recargar</Button></div>}
                        close
                        color="danger"
                        icon="info_outline"
                    />
                </CardBody>            
            </Card>
            <br/>
            <Card>
                <CardHeader color="info" className={classes.cardHeader}>
                    <h4 className={classes.cardTitle}>ETIQUETAS</h4>
                </CardHeader>
                <CardBody>
                    <SnackbarContent
                        message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple onClick={()=>refetch}><i className={" fab fa-refresh"}/></Button></div>}
                        close
                        color="danger"
                        icon="info_outline"
                    />
                </CardBody>            
            </Card>
        </>)
    if(loading) return (
        <>
            <Card>
                <CardHeader className={classes.cardHeader}>
                    <h4 className={classes.cardTitle}><Skeleton/></h4>
                </CardHeader>
                <CardBody>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                </CardBody>            
            </Card>
            <Card>
                <CardHeader className={classes.cardHeader}>
                    <h4 className={classes.cardTitle}><Skeleton/></h4>
                </CardHeader>
                <CardBody>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                </CardBody>            
            </Card>
        </>
    );
    return (
        <>
            <Card>
                <CardBody>
                    <div>
                        <CustomInput
                            primary
                            inputRootCustomClasses={classes.inputRootCustomClasses}
                            formControlProps={{
                                className: classes.formControl,
                            }}
                            inputProps={{
                                disabled:finding,
                                value:search,
                                onChange:onHandleChange,
                                placeholder: "Buscar",
                                inputProps: {
                                "aria-label": "Buscar",
                                className: classes.searchInput
                                }
                            }}
                        />
                        <Button justIcon round color="primary" disabled={search.toString().trim().length===0} onClick={()=>onHandleClick()}>
                            {finding?<CloseOutlined className={classes.searchIcon} />:<Search className={classes.searchIcon} />}
                        </Button>
                    </div>
                </CardBody>            
            </Card>
            <br/>
            <Card>
                <CardHeader color="primary" className={classes.cardHeader}>
                    <h4 className={classes.cardTitle}>CATEGORÍAS</h4>
                </CardHeader>
                <div style={{maxHeight:"400px",overflowY:"scroll"}}>
                    <CardBody>                    
                        {
                            data.data && data.status?data.data.categorias.map((item,index)=>(
                                <div key={index}>
                                    <h5 style={{fontSize:"0.8rem"}} className={selectedCategory!=item?classes.customCategoryStyle:classes.customCategorySelectedStyle} onClick={()=>onChangeCategoria(item)}>
                                        {String(item).toUpperCase()}                                    
                                    </h5>
                                    <hr className={classes.customBorderStyle}/>
                                </div>
                            )):null
                        }
                    </CardBody>
                </div>
            </Card>
            <br/>
            <Card>
                <CardHeader color="primary" className={classes.cardHeader}>
                    <h4 className={classes.cardTitle}>ETIQUETAS</h4>
                </CardHeader>
                <CardBody>
                    {
                        data.data && data.status?data.data.palabras.map((item,index)=>(                            
                            <span key={`badge${index}`} className={classes.customBadge} onClick={()=>onChangeTag(item)}><Badge color={selectedTag==item?'primary':'info'}>{item}</Badge></span>
                        )):null
                    }                    
                </CardBody>
            </Card>
        </>
    );
}

export default withRouter(CardSidebarComponent);