import React from "react";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";
import ReactPlayer from 'react-player/youtube';
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import Skeleton from 'react-loading-skeleton';
import { cardTitle } from "assets/jss/material-kit-react.js";

const styles = {
  ...imagesStyles,
  cardTitle,
  tamaniovideo:{
    height: "100%",
    width:"100%"
  }
};

const useStyles = makeStyles(styles);

export default function CardVideo(props) {
  const {loading=false} = props;
  const {data={titulo:null,descripcion:null,linkDescarga:null}} = props;  
  const classes = useStyles();
  if(loading) return (
    <Card>
      {
        <Skeleton height="480px"/>
      }
      <CardBody style={{paddingBottom:'0px'}}>        
        <h4 className={classes.cardTitle}><Skeleton/></h4>        
        <p><Skeleton/></p>
      </CardBody>
    </Card>
  )
  return (
    <Card className={classes.tamaniovideo}>
      {
        data.linkDescarga?
          <ReactPlayer url={data.linkDescarga} width="100%"/>:null
      }
      <CardBody style={{padding:'0'}}>
        {
          data.titulo?
            <h4 className={classes.cardTitle} style={{textAlign: "center"}}>{data.titulo}</h4>:null
        }
        <p style={{textAlign: "center"}}>{data.descripcion}</p>
      </CardBody>
      {
        false?
        <CardFooter style={{justifyContent:'center'}}>
              <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
              >
                  <i className={classes.socials + " fab fa-twitter"} />
              </Button>
              <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
              >
                  <i className={classes.socials + " fab fa-instagram"} />
              </Button>
              <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
              >
                  <i className={classes.socials + " fab fa-facebook"} />
              </Button>
          </CardFooter>:null
      }
    </Card>
  );
}