import { createSlice } from '@reduxjs/toolkit';

const playerSlice = createSlice({
  name: 'player',
  initialState: {
    audio: null,
    isPlaying: false,
  },
  reducers: {
    setAudio: (state, action) => {
      state.audio = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
  },
});

export const { setAudio, setIsPlaying } = playerSlice.actions;

export default playerSlice.reducer;