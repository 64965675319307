import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import useAxios from 'axios-hooks';
import Button from "components/CustomButtons/Button.js";
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from 'react-loading-skeleton';
import GLOBAL from 'global';

const HoyEnLaHistoria = () => {
    const [{ data, loading, error }, refetch] = useAxios(
        `${GLOBAL.apiURL}/api/historia/hoyHistoria`
    );
    if (error) return <SnackbarContent
                        message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button simple><i className={" fab fa-refresh"}/></Button></div>}
                        close
                        color="danger"
                        icon="info_outline"
                    />
    if (loading) 
        return(
            <div>
                <Skeleton width='100%' height='320px'/>
                <Skeleton width='100%'/>
            </div>
        )
    if (data && data.status && data.data && data.data.length==0) return <SnackbarContent
                                                                                message={<div><b>No hay datos para este día</b><Button simple><i className={" fab fa-refresh"}/></Button></div>}
                                                                                close
                                                                                color="info"
                                                                                icon="info_outline"
                                                                            />
    return (
        <Carousel>
            {
                data && data.status && data.data?data.data.map((item,index)=>(
                    <div key={index}>
                        <img src={item.imagen} />
                        <div className="legend">
                            <h3>
                                <span>{item.titulo}</span><br/>
                                <small>{item.fecha}</small>
                            </h3>
                            <p>{item.acontecimiento}</p>
                        </div>
                    </div>
                )):null
            }
        </Carousel>
    );
}

export default HoyEnLaHistoria;