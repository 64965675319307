import React from "react";
// react component for request
import useAxios from 'axios-hooks';
// react component for creating beautiful carousel
import Carousel from "react-slick";
import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from 'react-loading-skeleton';
import GLOBAL from "global";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function CarouselSection() {
  const classes = useStyles();
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/inicio/getBanners`
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  if (error || (data && !data.status)) return <SnackbarContent
                        message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                        close
                        color="danger"
                        icon="info_outline"
                    />
  if (loading) return(
    <GridContainer>
      <GridItem xs={1}>
        <Skeleton circle={true}/>
      </GridItem>
      <GridItem xs={10}>
        <Skeleton height='648px'/>
      </GridItem>
      <GridItem xs={1}>
        <Skeleton circle={true}/>
      </GridItem>
    </GridContainer>
  )

  return (
    <>      
      <Carousel {...settings}>
        {
          data.data?data.data.map((item,index) => (
            <div key={`slide${index}`}>
              <img src={item.bgImage} alt={item.texto} className="slick-image" />
              <div className="slick-caption">
                <h4 className={classes.customCaption}>
                  {item.texto}
                </h4>
              </div>
            </div>
          )):null
        }
      </Carousel>           
    </>
  );
}
