import React from "react";
import ReactDOM from "react-dom";

import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";

import InicioView from "views/Inicio/InicioView";
import ContactoView from "views/Contacto/ContactoView";
import GobiernoView from "views/Gobierno/GobiernoView";
import NoticiaView  from "views/Noticia/NoticiaView";
import SecretariaView from "views/Secretaria/SecretariaView";
import MultimediaView from "views/Multimedia/MultimediaView";
import RadioView from "views/Radio/RadioView";
import LeyesDecretosView from "views/LeyesDecretos/LeyesDecretosView";
import EncuestaView from "views/Encuesta/EncuestaView";
import PreguntasFrecuentes from "views/PreguntasFrecuentes/PreguntasFrecuentes";
import DenunciaView from "views/Denuncia/DenunciaView";
import SugerenciaView from "views/Sugerencia/SugerenciaView";
import ChildContactoView from "views/Childs/Contacto/ChildContactoView";
import ChildQuienesSomosView from "views/Childs/QuienesSomos/ChildQuienesSomosView";
import ChildDenunciaView from "views/Childs/Denuncia/ChildDenunciaView";
import ChildDependenciaView from "views/Childs/Dependencias/ChildDependenciaView";
import ChildMultimediaView from "views/Childs/Multimedia/ChildMultimediaView";
import ChildNoticiaView from "views/Childs/Noticias/ChildNoticiaView";
import ServiciosView from "views/Servicios/ServiciosView";
import ChildDocumentosView from "views/Childs/Documentos/ChildDocumentosView";
import ChildProgramasView from "views/Childs/Programas/ChildProgramasView";
import ChildPublicacionesView from "views/Childs/Publicaciones/ChildPublicacionesView";
import ChildRendicionCuentas from "views/Childs/RendicionCuentas/ChildRendicionCuentasView";


import ChildConvocatoriaView from "views/Childs/Convocatorias/ChildConvocatoriaView";
import ChildConvocatoriaDetalleView from "views/Childs/Convocatorias/ChildConvocatoriaDetalleView";

// * Codigo modificado por David Salinas Poma UGE */
import ChildAuditoriaView from "views/Childs/Auditorias/ChildAuditoriaView";
import ChildAuditoriaDetalleView from "views/Childs/Auditorias/ChildAuditoriaDetalleView";
//* FIN Codigo modificado por David Salinas Poma UGE */



import ChildPublicacionesCientificasView from "views/Childs/PublicacionCientifica/ChildPublicacionCientificaView";
import ChildPublicacionCientificaDetalleView from "views/Childs/PublicacionCientifica/ChildPublicacionCientificaDetalleView";
import ChildEstadisticasView from "views/Childs/Estadisticas/ChildEstadisticasView";
import ChildEstadisticaDetalleView from "views/Childs/Estadisticas/ChildEstadisticaDetalleView";
import ChildEventoView from "views/Childs/Eventos/ChildEventosView";
import ChildEventoDetalleView from "views/Childs/Eventos/ChildEventoDetalleView";
import ChildServicioPublicoView from "views/Childs/ServiciosPublicos/ChildServiciosPublicosView";
import ChildServicioPublicoDetalleView from "views/Childs/ServiciosPublicos/ChildServicioPublicoDetalleView";
import ChildProductosView from "views/Childs/Productos/ChildProductosView";
import ChildProductosDetalleView from "views/Childs/Productos/ChildProductosDetalleView";
import ChildUbicacionesView from "views/Childs/Ubicaciones/ChildUbicacionesView";
import ChildUbicacionDetalleView from "views/Childs/Ubicaciones/ChildUbicacionDetalle";
import ChildProyectosView from "views/Childs/Proyectos/ChildProyectosView";
import GaleriaView from "views/Galeria/GaleriaView";
import ChildGaleriaView from "views/Childs/Galeria/ChildGaleriaView";
import ApoyoDirectoView from "views/ApoyoDirecto/ApoyoDirectoView";

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';

var hist = createBrowserHistory();
ReactDOM.render(
  <Provider store={store}>
  <Router history={hist}>
    <Switch>      
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/components" component={Components} />
      <Route path="/gobierno" component={GobiernoView} />
      <Route path="/servicios" component={ServiciosView} />
      <Route path="/noticias" component={NoticiaView} />
      <Route path="/noticia-detalle/:id" component={NoticiaView} />
      <Route path="/multimedia" component={MultimediaView} />
      {/* Codigo modificado por David Salinas Poma UGE */}
      <Route path="/radio" component={RadioView} />
      {/* FIN Codigo modificado por David Salinas Poma UGE */}
      <Route path="/galeria" component={ GaleriaView } />
      <Route path="/gaceta" component={NoticiaView} />
      <Route path="/leyes-decretos" component={LeyesDecretosView} />
      <Route path="/transparencia" component={NoticiaView} />
      <Route path="/contacto" component={ContactoView} />
      <Route path="/apoyo-directo" component={ApoyoDirectoView} />
      
      <Route path="/unidad/:child/ubicaciones" component={ChildUbicacionesView} />
      <Route path="/unidad/:child/ubicacion-detalle/:slug" component={ChildUbicacionDetalleView} />
      <Route path="/unidad/:child/productos" component={ChildProductosView} />
      <Route path="/unidad/:child/producto-detalle/:slug" component={ChildProductosDetalleView} />
      <Route path="/unidad/:child/servicios-publicos" component={ChildServicioPublicoView} />
      <Route path="/unidad/:child/servicio-publico-detalle/:slug" component={ChildServicioPublicoDetalleView} />
      <Route path="/unidad/:child/eventos" component={ChildEventoView} />
      <Route path="/unidad/:child/evento-detalle/:slug" component={ChildEventoDetalleView} />
      <Route path="/unidad/:child/estadisticas" component={ChildEstadisticasView} />
      <Route path="/unidad/:child/estadisticas-detalle/:slug" component={ChildEstadisticaDetalleView} />
      <Route path="/unidad/:child/publicaciones-cientificas" component={ChildPublicacionesCientificasView} />
      <Route path="/unidad/:child/publicaciones-cientificas-detalle/:slug" component={ChildPublicacionCientificaDetalleView} />
      <Route path="/unidad/:child/denuncia" component={ChildDenunciaView} />

      <Route path="/unidad/:child/convocatorias" component={ChildConvocatoriaView} />
      <Route path="/unidad/:child/convocatoria-detalle/:id" component={ChildConvocatoriaDetalleView} />

      {/* Codigo modificado por David Salinas Poma UGE */}
       <Route path="/unidad/:child/auditoria" component={ChildAuditoriaView} />
       <Route path="/unidad/:child/auditoria-detalle/:id" component={ChildAuditoriaDetalleView} />
      {/* FIN Codigo modificado por David Salinas Poma UGE */}
      
      <Route path="/unidad/:child/rendicion-cuentas" component={ChildRendicionCuentas} />
      <Route path="/unidad/:child/publicaciones" component={ChildPublicacionesView} />
      <Route path="/unidad/:child/proyectos" component={ChildProyectosView} />
      <Route path="/unidad/:child/programas" component={ChildProgramasView} />
      <Route path="/unidad/:child/documentos" component={ChildDocumentosView} />
      <Route path="/unidad/:child/noticias" component={ChildNoticiaView} />
      <Route path="/unidad/:child/noticia-detalle/:id" component={ChildNoticiaView} />
      <Route path="/unidad/:child/multimedia" component={ChildMultimediaView} />
      <Route path="/unidad/:child/galeria" component={ ChildGaleriaView } />
      <Route path="/unidad/:child/dependencia" component={ChildDependenciaView} />
      <Route path="/unidad/:child/contacto" component={ChildContactoView} />
      <Route path="/unidad/:child/quienes-somos" component={ChildQuienesSomosView} />
      <Route path="/unidad/:child" component={SecretariaView} />
      
      <Route path="/encuesta" component={EncuestaView} />
      <Route path="/preguntas-frecuentes" component={PreguntasFrecuentes} />
      <Route path="/denuncia" component={DenunciaView} />
      <Route path="/sugerencia" component={SugerenciaView} />     

      {/* Este es el componente inicio para el menu*/}
      <Route path="/" component={InicioView} />
    </Switch>
  </Router>,
  </Provider>,
  document.getElementById("root")
);
