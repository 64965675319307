import React from 'react';
import { useParams } from "react-router-dom";
//import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import Pagination from '@material-ui/lab/Pagination';
import HeaderLinks from "components/Header/ChildHeaderLinks";

import Parallax from "components/Parallax/Parallax.js";

//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import GLOBAL from 'global';
import CardVideo from "components/Card/CardVideo";

// styles
import { container, title } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";

import logoVertical from "assets/img/logo-horizontal.png";
import CardGaleria from 'components/Card/CardGaleria';
const dashboardRoutes = [];

const styles = {
  container,
  title:{
    ...title,
    color:'white',
    textAlign:'center',
    width:'100%'
  },
  ...myStyles,
  justifyCenter: {
    justifyContent: "center !important"
  },
  customPadding:{
    paddingTop:'10px',
    paddingBottom:'10px',
  }
}

const useStyles = makeStyles(styles);
const ChildGaleriaView = (props) => {
    const classes = useStyles();
    const { child } = useParams();
    const { ...rest } = props;
    const [ page,setPage ] = React.useState(1);
    const [{ data, loading, error }, refetch] = useAxios(
        `${GLOBAL.apiURL}/api/multimedia/geleria/${child}?limite=6&page=${page}`
    );
    const handleChange = (event, value) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setPage(value);
    };
    
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, []); 
    
    return (
        <div>
        <Header
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
            height: 400,
            color: "primary"
            }}
            {...rest}
        />
        <Parallax small className={classes.customParalaxBg}>
            <h1 className={classes.title}>DE TU INTERÉS<br/><small style={{color:'white'}}>{data && data.status?String(data.message).toUpperCase():''}</small></h1>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
            <div className={classes.section}>
                <GridContainer className={classes.justifyCenter}>
                {
                    error || (data && !data.status)?
                    <SnackbarContent
                        message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button  simple><i className={" fab fa-refresh"}/></Button></div>}
                        close
                        color="danger"
                        icon="info_outline"
                    />:null
                }
                {
                    data && data.status && data.data.data.length===0?
                    <SnackbarContent
                        message={<div><span>No hay imágenes en la galería</span><Button simple><i className={" fab fa-refresh"}/></Button></div>}
                        close
                        color="info"
                        icon="info_outline"
                    />:null
                }
                {
                    loading?
                    Array.from(new Array(3)).map((item,index)=>(
                    <GridItem key={`card${index}`} xs={12} sm={12} md={12} lg={12}>
                        <CardVideo loading/>
                    </GridItem>
                    )):null
                }
                {
                    data && data.data && data.data.data?data.data.data.map((item,index)=>(
                        <GridItem key={`card${index}`} xs={12} sm={12} md={12} lg={12} className={classes.customPadding}>
                            <CardGaleria data={item}/>
                        </GridItem>
                    )):null
                }            
                </GridContainer>
                <br/>
                <br/>
                {
                data && data.data && data.data.data?
                <GridContainer className={classes.justifyCenter}>
                    <GridItem xs={12} style={{textAlign:'center'}}>
                    <Pagination count={data.data.last_page} page={page} onChange={handleChange} />
                    </GridItem>
                </GridContainer>:null
                }
            </div>
            </div>
        </div>
        <Footer />
        </div>
    );
}

export default ChildGaleriaView;
