import React from "react";
import { useForm } from "react-hook-form";
import useAxios from 'axios-hooks';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput.js";
import HeaderLinks from "components/Header/myHeaderLinks.js";
import Danger from "components/Typography/Danger.js";
import Parallax from "components/Parallax/Parallax.js";
import CircularProgress from '@material-ui/core/CircularProgress';
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import GLOBAL from 'global';


// styles
import { container, title } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";

import logoVertical from "assets/img/logo-horizontal.png";
import Skeleton from "react-loading-skeleton";
const dashboardRoutes = [];

const styles = {
  container,
  title:{
    ...title,
    color:'white',
    textAlign:'center',
    width:'100%'
  },
  customtitle:{
    ...title,
    color:'black',
    textAlign:'center',
    width:'100%'
  },
  question:{
    ...title,
    color:'black',
    textAlign:'left',
    width:'100%'
  },
  answer:{
    ...title,    
    textAlign:'left',
    width:'100%'
  },
  ...myStyles,
  justifyCenter: {
    justifyContent: "center !important"
  },
  customPadding:{
    paddingTop:'10px',
    paddingBottom:'10px',
  }
}

const useStyles = makeStyles(styles);

export default function PreguntasFrecuentesView(props) {
  const classes = useStyles();
  const { register, handleSubmit, reset, errors,formState } = useForm({mode:"onChange"});
  const { ...rest } = props;
  const [onrequest,setOnrequest] = React.useState(false);
  const [{ data, loading, error }, refetch] = useAxios(
    {
        url:`${GLOBAL.apiURL}/api/denuncia/save`,
        method:'post'
    },
    {
        manual:true
    }
  ); 
  const onSubmit = (datos) => {
    console.log("datos=",datos);
    setOnrequest(true);
    
    refetch({
          data:{
              nombre:datos.nombre,
              celular:datos.celular,
              email:datos.email,
              denuncia:datos.mensaje
          }
      });
      
     
  }
  const onHandleChange = () => {
    setOnrequest(false);
    reset();
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []); 
  
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small className={classes.customParalaxBg}>
          <h1 className={classes.title}>DENUNCIAS</h1>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <GridContainer className={classes.justifyCenter}>
                  <GridItem xs={12}>
                      <h3 className={classes.customtitle}>HAGA SU DENUNCIA MEDIANTE EL SIGUIENTE FORMULARIO</h3>
                  </GridItem>
                  <GridItem xs={12}>
                      <br/>
                      <CustomInput
                          labelText="Ingrese su nombre completo"
                          id="nombre"
                          name="nombre"                       
                          inputProps={{
                              disabled:onrequest,
                              id:"nombre",
                              name:"nombre",
                              placeholder: "Ingrese su nombre completo",
                              inputRef:register(
                                { 
                                  required: true,
                                  minLength: 2,
                                  maxLength: 50                                  
                                })
                          }}
                          formControlProps={{
                              fullWidth: true
                          }}
                      />
                      {errors.nombre && errors.nombre.type === "required" && <Danger>Este dato es requerido</Danger>}
                      {errors.nombre && errors.nombre.type === "minLength" && <Danger color="error">Se requiere 2 caracteres como mínimo</Danger>}
                      {errors.nombre && errors.nombre.type === "maxLength" && <Danger color="error">Número de caracteres excedido</Danger>}                      
                  </GridItem>
                  <GridItem xs={12}>
                      <br/>
                      <CustomInput
                          labelText="Ingrese su Nro. celular"
                          id="celular"
                          name="celular"                       
                          inputProps={{
                              disabled:onrequest,
                              id:"celular",
                              name:"celular",
                              placeholder: "Celular",
                              inputRef:register(
                                { 
                                  required: true,
                                  minLength: 8,
                                  maxLength: 8,
                                  pattern: /[0-9]/                                  
                                })
                          }}
                          formControlProps={{
                              fullWidth: true
                          }}
                      />
                      {errors.celular && errors.celular.type === "required" && <Danger>Este dato es requerido</Danger>}
                      {errors.celular && errors.celular.type === "minLength" && <Danger color="error">Se requiere número de 8 dígitos como mínimo</Danger>}
                      {errors.celular && errors.celular.type === "maxLength" && <Danger color="error">Número de caracteres excedido</Danger>}
                      {errors.celular && errors.celular.type === "pattern" && <Danger color="error">Solo se permiten números</Danger>}
                  </GridItem>
                  <GridItem xs={12}>
                      <br/>
                      <CustomInput
                          labelText="Ingrese su email"
                          id="email"
                          name="email"                       
                          inputProps={{
                              disabled:onrequest,
                              id:"email",
                              name:"email",
                              placeholder: "Email",
                              inputRef:register(
                                { 
                                  required: true,
                                  minLength: 7,
                                  maxLength: 100,
                                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                                  
                                })
                          }}
                          formControlProps={{
                              fullWidth: true
                          }}
                      />
                      {errors.email && errors.email.type === "required" && <Danger>Este dato es requerido</Danger>}
                      {errors.email && errors.email.type === "minLength" && <Danger color="error">Se requiere 7 caracteres como mínimo</Danger>}
                      {errors.email && errors.email.type === "maxLength" && <Danger color="error">Número de caracteres excedido</Danger>}
                      {errors.email && errors.email.type === "pattern" && <Danger color="error">Formato de email incorrecto</Danger>}
                  </GridItem>
                  <GridItem xs={12}>
                      <br/>
                      <CustomInput
                          labelText="Ingrese su denuncia"
                          id="message"                          
                          formControlProps={{                              
                              fullWidth: true,
                              className: classes.textArea,                              
                          }}
                          inputProps={{
                              disabled:onrequest,
                              name:"mensaje",
                              placeholder:"Ingrese su denuncia",
                              multiline: true,
                              rows: 5,
                              inputRef:register(
                                { 
                                  required: true,
                                  minLength: 10,
                                  maxLength: 1000                                  
                                })
                          }}
                      />
                      {errors.mensaje && errors.mensaje.type === "required" && <Danger>Este dato es requerido</Danger>}
                      {errors.mensaje && errors.mensaje.type === "minLength" && <Danger>Se requiere 10 caracteres como mínimo</Danger>}
                      {errors.mensaje && errors.mensaje.type === "maxLength" && <Danger>Número de caracteres excedido</Danger>}                      
                      <div style={{marginTop:'20px',textAlign:'center'}}>
                        {
                          onrequest && error?               
                          <SnackbarContent
                              message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                              close
                              color="danger"
                              icon="info_outline"
                          />:null
                        }
                        {
                          loading?
                          <div style={{textAlign:"center"}}><CircularProgress /></div>:null
                        }
                        {
                          onrequest && data && !data.status?
                          <SnackbarContent
                              message={<div><span>{data.message}</span></div>}
                              close
                              color="danger"
                              icon="info_outline"
                          />:null
                        }
                        {
                          onrequest && data && data.status && data.data?
                          <SnackbarContent
                              message={<div><span>{data.message}</span></div>}
                              close
                              color="success"
                              icon="info_outline"
                          />:null
                        }                        
                        {
                          !onrequest?
                          <Button color={formState.isValid?"primary":"warning"} type="submit" disabled={!formState.isValid}>{formState.isValid?"ENVIAR":"Llene los campos correctamente"}</Button>:
                          <Button color="info" disabled={loading} onClick={()=>onHandleChange()}>HACER OTRA DENUNCIA</Button>
                        }                        
                      </div>                      
                  </GridItem>              
              </GridContainer>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
