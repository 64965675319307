import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from 'components/CustomButtons/Button.js';
// @material-ui/icons

import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";

import { container,title } from "assets/jss/material-kit-react.js";
//import logoQr from "../../../assets/img/gobiernoenlineaqrs/qr1.jpg";

const imagesQrs = require.context('../../../assets/img/gobiernoenlineaqrs',true);


const styles = {
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        ...container
    },
    section: {
        padding: "30px 0",
        textAlign: "center"
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "-10px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign:'left'
    },
    title2: {
        ...title,
        marginBottom: "0px",
        marginTop: "-10px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign:'center'
    },
    description: {
        color: "#999"
    },
    justifyCenter: {
        justifyContent: "center !important",
        paddingTop:'0'
    },
}

const useStyles = makeStyles(styles);

const datos = [
    {
        titulo: 'titulo 1',
        descripcion: 'descripcion 1',
        url: 'https://www.google.com',
        imagen: 'qr1',

    },
    {
        titulo: 'titulo 2',
        descripcion: 'descripcion 2',
        url: 'https://www.google.com',
        imagen: 'qr2',

    },
    {
        titulo: 'titulo 3',
        descripcion: 'descripcion 3',
        url: 'https://www.google.com',
        imagen: 'qr2',

    },
    {
        titulo: 'titulo 4',
        descripcion: 'descripcion 4',
        url: 'https://www.google.com',
        imagen: 'qr4',

    },
    {
        titulo: 'titulo 5',
        descripcion: 'descripcion 5',
        url: 'https://www.google.com',
        imagen: 'qr5',

    },
];

const GobiernoEnLineaSection = () => {
    const classes = useStyles();
    //console.log( imagesQrs(`./qr1.jpg`).default );
    return (
        <div>
            <div className={classes.container}>
                <div className={classes.section}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                            <h2 className={classes.title}>GOBERNACIÓN EN LINEA</h2>                    
                        </GridItem>
                    </GridContainer>
                    
                    <div style={{marginTop: "-40px"}}>
                        <GridContainer className={classes.justifyCenter}>
                            {
                                datos.map((item,index)=>(
                                    <GridItem key={`card${index}`} xs={12} sm={12} md={4} style={{paddingTop:'10px',paddingBottom:'10px'}}>                                    
                                        
                                        <Card style={{width: "100%"}}>
                                            <img src={ imagesQrs(`./qr1.jpg`).default } alt={item.titulo} width="100%"/>
                                            {/* <img src={ logoQr } alt={item.titulo} width="100%"/> */}
                                            <CardBody>                                        
                                                <h4 className={classes.title2}>
                                                    {item.titulo}<br/>
                                                    <small>{item.descripcion}</small>                                            
                                                </h4>                                        
                                            </CardBody>
                                            <CardFooter className={classes.justifyCenter}>
                                                <Button color="primary" onClick={()=>window.open(item.url, '_blank')}>
                                                    <OpenInBrowser/>{" "}Abrir
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                ))
                                
                            }
                        </GridContainer>
                    </div>
                </div>
            </div>
        </div>
      );
}

export default GobiernoEnLineaSection;