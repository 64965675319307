import React from "react";
import useAxios from 'axios-hooks';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from 'components/CustomButtons/Button.js';
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import HeaderLinks from "components/Header/myHeaderLinks";
import Parallax from "components/Parallax/Parallax.js";
import logoVertical from "assets/img/logo-horizontal.png";
import LinkRounded from "@material-ui/icons/LinkRounded";

import styles from "assets/jss/material-kit-react/views/gobiernoView/gobiernoViewStyle";

import GLOBAL from 'global';
import Skeleton from "react-loading-skeleton";
import { Divider } from "@material-ui/core";
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
var elem = null;
export default function GobiernoView(props) {
  const classes = useStyles();
  const imageClasses = classNames(
    //classes.imgRoundedCircle,
    // classes.imgRounded,
    // classes.imgFluid,
    classes.imagenBiografia,
  );
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/inicio/getDespacho`
  );
  const { ...rest } = props;


  const createMarkup = (htmlContent) =>{    
    return {__html: htmlContent};
  }

  if (data) {
    elem = localStorage.getItem('gobierno');
    try {
      elem = document.getElementById(elem);
      if (elem) {
        console.log("elem to go=",elem);
        setTimeout(()=>{
          console.log("En accion");
          console.log((elem.offsetTop + 250));
          window.scrollTo(0, (elem.offsetTop + 250));
        },1000);          
      }
    } catch (error) {
      //setTimeout(()=>{window.scrollTo(0,elem.offsetTop);},1000);      
      console.log("BAD");
    }

  }
  //React.useEffect(() => {
  //console.log("*********** GobiernoView *************");
  //window.scrollTo(0, 0);    
  //}, []);
  if (error) return <SnackbarContent
    message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"} /></Button></div>}
    close
    color="danger"
    icon="info_outline"
  />
  
  

  return (
    <>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.img} alt="Logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small className={classes.customParalaxBg}>
        <h2 className={classes.title}>{data && data.data && data.data.despacho ? data.data.despacho.nombre : ''}</h2>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.customMargin}>
          <div className={classes.container}>
            {
              error?
              <SnackbarContent
                  message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                  close
                  color="danger"
                  icon="info_outline"
              />:null
            }
            
            

            <div id="biografia">
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <Card plain>
                    <CardBody>
                      <h3 className={classes.title2}>AUTORIDAD</h3>
                      <GridItem 
                        xs={12} sm={12} md={6} 
                        className={classes.itemDivImagenBiografia}
                      >
                        {
                          loading?
                          <Skeleton width="200px" height="200px"/>:
                          <img 
                            src={data.data && data.data.biografia?data.data.biografia.foto:null} 
                            alt="..."  
                            className={imageClasses}                             
                            onClick={()=> window.open(`${data.data && data.data.biografia?data.data.biografia.foto:'...'}`, "_blank")}
                          />
                        }
                      </GridItem>
                      <h4 className={classes.cardTitle} style={{ textAlign: 'center' }}>              
                          {
                            loading?
                            <Skeleton/>:
                            <>
                              {data.data && data.data.biografia?data.data.biografia.nombreCompleto:null}<br/>
                              <small className={classes.description}>{data.data && data.data.biografia?data.data.biografia.profesion:null}</small>
                            </>
                          }
                        </h4>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <br/>
                  <br/>
                  <h3 className={classes.title2}>BIOGRAFÍA</h3>
                  <div>
                    <p>
                      {
                        loading?
                        <Skeleton/>:
                        // data.data && data.data.biografia?data.data.biografia.resenia:null
                        <div dangerouslySetInnerHTML={createMarkup( (data.data && data.data.biografia)?data.data.biografia.resenia:'')}></div>
                      }
                    </p>
                  </div>
                </GridItem>
              </GridContainer>
            </div>                                  

            <GridContainer>
              <GridItem xs={12}>
                  <h3 className={classes.title2}>MISIÓN</h3>
                  <div className={classes.description}>
                      <p>
                        {
                          loading?
                          <Skeleton/>:
                          data.data && data.data.despacho?data.data.despacho.mision:null
                        }
                      </p>
                  </div>
                  <h3 className={classes.title2}>VISIÓN</h3>
                  <div className={classes.description}>
                      <p>
                        {
                          loading?
                          <Skeleton/>:
                          data.data && data.data.despacho?data.data.despacho.vision:null
                        }
                      </p>
                  </div>
                  <h3 className={classes.title2}>OBJETIVOS</h3>
                  <div className={classes.description}>                      
                      <p>
                        {
                          loading?
                          <Skeleton/>:
                          data.data && data.data.despacho?data.data.despacho.objetivo:null
                        }
                      </p>
                  </div>
                  <h3 className={classes.title2}>HISTORIA</h3>
                  <div className={classes.description}>
                      <p>
                        { 
                          loading?
                          <Skeleton/>:
                          data.data && data.data.despacho?data.data.despacho.historia:''
                        }
                      </p>
                  </div>
              </GridItem>
            </GridContainer>
            
            <br/><br/>
            <Divider />
            

            <div id="secretarias">
              <GridContainer className={classes.justifyCenter}>
                  <h3 className={classes.title2}>SECRETARÍAS</h3>
                  {
                    loading?
                    Array.from(new Array(6)).map((item,index)=>(
                      <GridItem key={`secretaria${index}`} xs={12} sm={6} md={4}>
                        <Skeleton height="320px"/>
                      </GridItem>
                    )):
                    data.data && data.data.secretarias? data.data.secretarias.map((item, index) => (
                      <GridItem key={`secretaria${index}`} xs={12} sm={6} md={4} className={classes.customPadding}>
                          <Card style={{width: "100%",height:'100%',cursor:'pointer'}} onClick={() => props.history.push(`/unidad/${item.undId}`)}>
                              <img src={item.imagenIcono} alt={item.nombre} width="100%" />                            
                              <h3 className={classes.title3}>
                                  {item.nombre}                                                
                              </h3>
                              
                              {
                                false?
                                <CardFooter className={classes.justifyCenter}>
                                    <Button
                                        color="primary"
                                        className={classes.margin5}
                                        onClick={() => props.history.push(`/unidad/${item.undId}`)}
                                    >
                                        <LinkRounded />{' '}VISITAR
                                </Button>
                                </CardFooter>:null
                              }
                          </Card>
                      </GridItem>
                    )) : null
                  }
              </GridContainer>
            </div> 

            <br/><br/><br/><br/>
            <Divider />
            
            <div id="organigrama">
              <GridContainer>                        
                <GridItem xs={12}>
                  <h3 className={classes.title2}>ORGANIGRAMA</h3>
                  {
                    loading?
                    <Skeleton/>:
                    data.data && data.data.despacho?
                    <iframe width="100%" height="520px" src={data.data.despacho.organigrama}></iframe>:null
                  }                  
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </>
  );
}
