//import {} from "assets/jss/material-kit-react.js";
const myStyles = {
    customParalaxBg:{
        //backgroundColor:'#227dcb',        
        //background: "rgb(0,172,201)!important",
        background: "linear-gradient(60deg, #66cef6, #227dcb)!important",
    },
    logoImg:{
        width: "200px",
    },
    subtitle: {
        fontSize: "1.313rem",
        maxWidth: "500px",
        margin: "10px auto 0"
    },
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
    },
    mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow:
          "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    section: {
        padding: "70px 0"
    },
    relleno:{
        height:'150px'
    },
}
export default myStyles;