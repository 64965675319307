import React from "react";
import { useParams } from "react-router-dom";
//import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card";
import CardMedia from '@material-ui/core/CardMedia';
import CardBody from "components/Card/CardBody";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import Pagination from '@material-ui/lab/Pagination';
import HeaderLinks from "components/Header/ChildHeaderLinks";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Search from "@material-ui/icons/Search";
import CloseOutlined from "@material-ui/icons/CloseOutlined";

// core components
import Parallax from "components/Parallax/Parallax.js";

//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from "react-loading-skeleton";
import GLOBAL from 'global';

// styles
import { container, title,  } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

import logoVertical from "assets/img/logo-horizontal.png";
import Slider from "react-slick";

const dashboardRoutes = [];

const styles = {
  container,
  title:{
    ...title,
    color:'white',
    textAlign:'center',
    width:'100%'
  },
  titleData:{
    ...title,    
    textAlign:'center',
    width:'100%',
    borderRadius: '25px',
    background:'#8fc9e8'
  },
  subtitleData:{
    ...title,    
    textAlign:'left',
    display: 'table-cell',
    verticalAlign: 'middle',
    height: '55px'
  },
  pdata:{
    display: 'table-cell',
    verticalAlign: 'middle',
    height: '55px',
    whiteSpace: 'pre-line'
  },
  ...myStyles,
  ...imagesStyles,
  justifyCenter: {
    justifyContent: "center !important"
  },
  customPadding:{
    paddingTop:'10px',
    paddingBottom:'10px',
  },
  inputRootCustomClasses: {
    margin: "0!important",        
  },
  formControl: {
    margin: "0 !important",
    paddingTop: "0",
    width:"80%"
  },
  searchIcon: {
    width: "20px",
    height: "20px",
    color: "inherit"
  },
  dividerStyle:{
    border:'solid 1px #e9e9e9'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    marginLeft: '20%',
    marginRight: '20%',
  },
  carouselimagenes:{
    marginLeft: '20%',
    marginRight: '20%',
    marginBottom: '40px'
  }
}

const useStyles = makeStyles(styles);

const MyContent = (props) => {      
    const {data=null} = props;  
    const classes = useStyles();

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      adaptativeHeight: true,
      className: classes.carouselimagenes
    };
    //console.log('datos',data);    
    
    return(
      <>        
        <h4 className={classes.titleData}>{String(data.nombre).toUpperCase()}</h4>
        <hr className={classes.dividerStyle}/>

        {/* <CardMedia
            className={classes.media}
            image={data.imagen}
            title="portada"
        /> */}

        {
            data && data.proyecto_imagenes && data.proyecto_imagenes.length>0?                                            
            <Slider {...settings}>
                {
                  data?data.proyecto_imagenes.map((item,index)=>(
                  <div key={`item${index}`} className={classes.customCaption}>
                      <img src={item.imagen} alt={item.titulo} className="slick-image"/>
                      {/* <p className="slick-caption" style={{textShadow:'2px 2px 5px #000000'}}>{item.descripcion}</p> */}
                  </div>
                )):null                  
                }
            </Slider>            
            :null
        }

        <GridContainer>
          <GridItem xs={12} sm={4}>
            <h6 className={classes.subtitleData}>DESCRIPCIÓN</h6>
          </GridItem>
          <GridItem xs={12} sm={8}>
            <p className={classes.pdata}>{data.descripcion}</p>
          </GridItem>          
        </GridContainer>
        <hr className={classes.dividerStyle}/>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <h6 className={classes.subtitleData}>INVERSIÓN</h6>
          </GridItem>
          <GridItem xs={12} sm={8}>
            <p className={classes.pdata}>{data.inversion}</p>
          </GridItem>          
        </GridContainer>
        <hr className={classes.dividerStyle}/>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <h6 className={classes.subtitleData}>FINANCIAMIENTO</h6>
          </GridItem>
          <GridItem xs={12} sm={8}>
            <p className={classes.pdata}>{data.fuentefinanciamento}</p>
          </GridItem>          
        </GridContainer>
        <hr className={classes.dividerStyle}/>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <h6 className={classes.subtitleData}>ESTADO DEL PROYECTO</h6>
          </GridItem>
          <GridItem xs={12} sm={8}>
            <p className={classes.pdata}>{data.estadoproyecto}</p>
          </GridItem>          
        </GridContainer>
        <hr className={classes.dividerStyle}/>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <h6 className={classes.subtitleData}>EJECUCIÓN FÍSICA</h6>
          </GridItem>
          <GridItem xs={12} sm={8}>
            <p className={classes.pdata}>{data.ejecucionfisica}</p>
          </GridItem>          
        </GridContainer>        
      </>
    );
}


export default function ChildProyectosView(props) {
  const classes = useStyles();
  const { child } = useParams();
  const { ...rest } = props;
  const [ search,setSearch ] = React.useState('');
  const [finding,setFinding] = React.useState(false);
  const [ text,setText ] = React.useState('');
  const [ page,setPage ] = React.useState(1);
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/unidad/proyectos/${child}?limite=5&search=${search}&page=${page}`
  );
  const handleChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(value);
  };
    const onHandleChange = (event) => {        
        setText(event.target.value);
    }
    const onHandleClickSearch = () => {
        if(finding){
            setFinding(!finding);
            setSearch('');
            setText('');
            //props.onChangeSearch('');
        }else{
            setFinding(!finding);
            setSearch(text);
            //props.onChangeSearch(search);
            //console.log("enviara search=",search);
        }
    }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []); 
  
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small className={classes.customParalaxBg}>
          <h1 className={classes.title}>PROYECTOS<br/><small style={{color:'white'}}>{data && data.status?String(data.message).toUpperCase():''}</small></h1>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <GridContainer>
              <GridItem xs={12}>
                {
                  error || (data && !data.status)?
                  <SnackbarContent
                      message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                      close
                      color="danger"
                      icon="info_outline"
                  />:null
                }
                {
                  data && data.status && data.data.data.length===0?
                  <SnackbarContent
                      message={<div><span>No hay datos para mostrar</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                      close
                      color="info"
                      icon="info_outline"
                  />:null
                }
                {
                  loading?
                  Array.from(new Array(3)).map((item,index)=>(
                    <GridItem key={`card${index}`} xs={12} sm={12}>
                      <Skeleton height='320px'/>
                    </GridItem>
                  )):null
                }
              </GridItem>              
            </GridContainer>

            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={6}>
                    <div>
                        <CustomInput
                            primary
                            inputRootCustomClasses={classes.inputRootCustomClasses}
                            formControlProps={{
                                className: classes.formControl,
                            }}
                            inputProps={{
                                disabled:finding,
                                value:text,
                                onChange:onHandleChange,
                                placeholder: "Buscar",
                                inputProps: {
                                "aria-label": "Buscar",
                                className: classes.searchInput
                                }
                            }}
                        />
                        <Button justIcon round color="primary" disabled={text.toString().trim().length===0} onClick={()=>onHandleClickSearch()}>
                            {finding?<CloseOutlined className={classes.searchIcon} />:<Search className={classes.searchIcon} />}
                        </Button>
                    </div>
                </GridItem>
                {
                  data && data.data && data.data.data?data.data.data.map((item,index)=>(
                    <GridItem key={`card${index}`} xs={12}>
                          <Card>
                              <CardBody>
                                  <MyContent data={item}/>
                              </CardBody>
                        </Card>
                    </GridItem>
                  )):null
                }
            </GridContainer>  

            {
              data && data.data && data.data.data?
              <GridContainer className={classes.justifyCenter}>
                <GridItem xs={12} style={{textAlign:'center'}}>
                  <Pagination count={data.data.last_page} page={page} onChange={handleChange} />
                </GridItem>
              </GridContainer>:null
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
