/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles1 from "assets/jss/material-kit-react/components/footerStyle.js";
import styles2 from "assets/jss/material-kit-react/components/headerLinksStyle.js";
const styles = {
  ...styles1,
  ...styles2
}

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>        
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock} style={{padding:'15px'}}>
                <Link style={{fontWeight:'bold'}} to="/preguntas-frecuentes" className={classes.navLink}>
                  ¿PREGUNTAS FRECUENTES?
                </Link>
              </ListItem>              
            </List>
          </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()} ,{" "}
          <a
            href="#"
            className={aClasses}
            target="_blank"
          >
            Gobierno Autónomo Departamental de Cochabamba
            </a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
