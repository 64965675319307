import React from "react";
import useAxios from 'axios-hooks';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from 'components/CustomButtons/Button.js';
// @material-ui/icons
import PictureAsPdfRounded from "@material-ui/icons/PictureAsPdfRounded";
import TabOutlined from "@material-ui/icons/TabOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from 'react-loading-skeleton';
import GLOBAL from 'global';

import styles from "assets/jss/material-kit-react/views/inicioView/guiaTramitesSectionStyle";

const useStyles = makeStyles(styles);

export default function GuiaTramitesSection() {
  const classes = useStyles();
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/inicio/getGuiasTramites`
  );
    
  const downloadFile = (uri,nombre="archivo") => {
    let link = document.createElement("a");
    link.download = nombre;
    link.href = uri;   
    link.click();    
    link = null;
  }

  if (error) return <SnackbarContent
                        message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                        close
                        color="danger"
                        icon="info_outline"
                    />
  if (loading) return(
        <div className={classes.container}>
            <div className={classes.section}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}><Skeleton width='100%'/></h2>                    
                    </GridItem>
                </GridContainer>
                <div>
                    <GridContainer>                        
                        <GridItem xs={12} sm={12} md={4}>
                            <Skeleton width='100%' height='480px'/>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Skeleton width='100%' height='480px'/>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Skeleton width='100%' height='480px'/>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
  )

  return (
    <div>
        <div className={classes.container}>
            <div className={classes.section}>
                {
                    data.data.length>0
                    ?(
                        <GridContainer className={classes.justifyCenter}>
                            <GridItem xs={12} sm={12} md={12}>
                                <h2 className={classes.title}>GUÍA DE TRÁMITES</h2>                    
                            </GridItem>
                        </GridContainer>
                    ):null
                }                
                <GridContainer className={classes.justifyCenter}>
                    {
                        data.data.map((item,index)=>(
                            <GridItem key={`card${index}`} xs={12} sm={12} md={4} style={{paddingTop:'10px',paddingBottom:'10px'}}>
                                <Card style={{width: "100%",height:'100%'}}>
                                    <CardBody>
                                        <InfoArea
                                            title={item.titulo}
                                            description={item.descripcion}
                                            icon={PictureAsPdfRounded}
                                            iconColor="primary"
                                            vertical
                                        />
                                    </CardBody>
                                    <CardFooter className={classes.justifyCenter}>
                                        <Button color="primary" onClick={()=>window.open(item.archivo, '_blank')}>
                                            <TabOutlined/>{" "}Abrir
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        ))
                        
                    }
                </GridContainer>
                
            </div>
        </div>
    </div>
  );
}
