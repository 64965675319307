import React from "react";
import Button from "components/CustomButtons/Button.js";
import {withRouter} from "react-router-dom";
import CardMedia from '@material-ui/core/CardMedia';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import cardNoticiaStyles from "assets/jss/material-kit-react/views/inicioView/cardNoticia";
import 'assets/css/inicioView/noticiaSection/cardNoticia.css';
import moment from "moment";
import 'moment/locale/es';
import Helpers from "helpers";

moment.locale('es');
const useStyles = makeStyles(cardNoticiaStyles);

const CardNoticia = (props) => {
  const {preview=false,data=null} = props;
  const classes = useStyles();
  return (
   <figure className='customCard'>
   
     <CardMedia
        className={classes.customImage}
        image={data.imagen}
        title={String(data.titulo).toUpperCase()}
      />      
     <figcaption className='customCaptionSocial'>
     <Button
        justIcon
        color="transparent"
        className={classes.margin5}
        onClick={()=>Helpers.shareFacebook(window.location.href)}
      >
        <i className={classes.socials + " fab fa-facebook-square"} style={{color:'white'}}/>
      </Button>
      <Button
        justIcon
        color="transparent"
        className={classes.margin5}
        onClick={()=>Helpers.shareTwitter(window.location.href,data.titulo)}
      >
        <i className={classes.socials + " fab fa-twitter"} style={{color:'white'}}/>
      </Button>
      <Button
        justIcon
        color="transparent"
        className={classes.margin5}
        onClick={()=>Helpers.shareWhatsapp(window.location.href)}
      >
        <i className={classes.socials + " fab fa-whatsapp"} style={{color:'white'}}/>
      </Button>
     </figcaption>

    <figcaption className={classes.cardTitle+' customCaption'} onClick={()=>props.history.push(`/noticia-detalle/${data?data.slug:''}`)}>
      <span>{String(data.titulo).toUpperCase()}</span>
      <br/>
      {
        data.fecha?
        <small className={classes.customSmallText}>Publicado el {moment(data.fecha).format('LL')}</small>:null
      }
    </figcaption>
    
   </figure> 
  );
}
 
export default withRouter(CardNoticia);