import React from "react";
import { useParams } from "react-router-dom";
//import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import Pagination from '@material-ui/lab/Pagination';
import HeaderLinks from "components/Header/ChildHeaderLinks";
import InfoArea from "components/InfoArea/InfoArea.js";
import CardFooter from "components/Card/CardFooter";
// @material-ui/icons
import Search from "@material-ui/icons/Search";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import PictureAsPdfRounded from "@material-ui/icons/PictureAsPdfRounded";
import TabOutlined from "@material-ui/icons/TabOutlined";
// core components
import Parallax from "components/Parallax/Parallax.js";

//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Skeleton from "react-loading-skeleton";
import GLOBAL from 'global';

// styles
import { container, title,  } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";

import logoVertical from "assets/img/logo-horizontal.png";

const dashboardRoutes = [];

const styles = {
  container,
  title:{
    ...title,
    color:'white',
    textAlign:'center',
    width:'100%'
  },
  titleData:{
    ...title,    
    textAlign:'center',
    width:'100%',
    borderRadius: '25px',
    background:'#8fc9e8'
  },
  subtitleData:{
    ...title,    
    textAlign:'left',
    display: 'table-cell',
    verticalAlign: 'middle',
    height: '55px'
  },
  pdata:{
    display: 'table-cell',
    verticalAlign: 'middle',
    height: '55px'
  },
  ...myStyles,
  justifyCenter: {
    justifyContent: "center !important"
  },
  customPadding:{
    paddingTop:'10px',
    paddingBottom:'10px',
  },
  inputRootCustomClasses: {
    margin: "0!important",        
  },
  formControl: {
    margin: "0 !important",
    paddingTop: "0",
    width:"80%"
  },
  searchIcon: {
    width: "20px",
    height: "20px",
    color: "inherit"
  },
  dividerStyle:{
    border:'solid 1px #e9e9e9'
  }
}

const useStyles = makeStyles(styles);

const MyContent = (props) => {      
    const {data=null} = props;  
    const classes = useStyles();
    return(
      <>        
        <Card style={{width: "100%",height:'100%',textAlign:'center'}}>
            <CardBody>
                <InfoArea
                    title={data.titulo}
                    description={data.descripcion}
                    icon={PictureAsPdfRounded}
                    iconColor="primary"
                    vertical
                />
            </CardBody>
            <CardFooter className={classes.justifyCenter}>
                <Button color="primary" onClick={()=>window.open(data.archivo, '_blank')}>
                    <TabOutlined/>{" "}Abrir
                </Button>
            </CardFooter>
        </Card>
      </>
    );
}


export default function ChildRendicionCuentasView(props) {
  const classes = useStyles();
  const { child } = useParams();
  const { ...rest } = props;
  const [ search,setSearch ] = React.useState('');
  const [finding,setFinding] = React.useState(false);
  const [ text,setText ] = React.useState('');
  const [ page,setPage ] = React.useState(1);
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/unidad/rendicioncuentas/${child}?limite=10&search=${search}&page=${page}`
  );
  const handleChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(value);
  };
  const onHandleChange = (event) => {        
        setText(event.target.value);
    }
    const onHandleClickSearch = () => {
        if(finding){
            setFinding(!finding);
            setSearch('');
            setText('');
            //props.onChangeSearch('');
        }else{
            setFinding(!finding);
            setSearch(text);
            //props.onChangeSearch(search);
            //console.log("enviara search=",search);
        }
    }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []); 
  
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small className={classes.customParalaxBg}>
          <h1 className={classes.title}>RENDICIÓN DE CUENTAS<br/><small style={{color:'white'}}>{data && data.status?String(data.message).toUpperCase():''}</small></h1>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <GridContainer>
              <GridItem xs={12}>
                {
                  !loading && error || (data && !data.status)?
                  <SnackbarContent
                      message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                      close
                      color="danger"
                      icon="info_outline"
                  />:null
                }
                {
                  !loading && data && data.status && data.data.data.length===0?
                  <SnackbarContent
                      message={<div><span>No hay datos para mostrar</span><Button color="light" simple><i className={" fab fa-refresh"}/></Button></div>}
                      close
                      color="info"
                      icon="info_outline"
                  />:null
                }                
              </GridItem>              
            </GridContainer>
            <GridContainer>
                {
                  loading?
                  Array.from(new Array(3)).map((item,index)=>(
                    <GridItem key={`card${index}`} xs={12} sm={6} md={4}>
                      <Skeleton height='320px'/>
                    </GridItem>
                  )):null
                }
            </GridContainer>
            <GridContainer justify='center'>
                {
                    !loading && data && data.data && data.data.data?
                    <GridItem xs={12} sm={12} md={6}>
                        <div>
                            <CustomInput
                                primary
                                inputRootCustomClasses={classes.inputRootCustomClasses}
                                formControlProps={{
                                    className: classes.formControl,
                                }}
                                inputProps={{
                                    disabled:finding,
                                    value:text,
                                    onChange:onHandleChange,
                                    placeholder: "Buscar",
                                    inputProps: {
                                    "aria-label": "Buscar",
                                    className: classes.searchInput
                                    }
                                }}
                            />
                            <Button justIcon round color="primary" disabled={text.toString().trim().length===0} onClick={()=>onHandleClickSearch()}>
                                {finding?<CloseOutlined className={classes.searchIcon} />:<Search className={classes.searchIcon} />}
                            </Button>
                        </div>
                    </GridItem>:null
                }
            </GridContainer>
            <GridContainer>
                {
                  !loading && data && data.data && data.data.data?data.data.data.map((item,index)=>(
                    <GridItem key={`card${index}`} xs={12} sm={6} md={4}>                          
                        <MyContent data={item}/>                          
                    </GridItem>
                  )):null
                }
            </GridContainer>  
            <br/><br/>
            {
              !loading && data && data.data && data.data.data?
              <GridContainer>
                <GridItem xs={12} style={{textAlign:'center'}}>
                  <Pagination count={data.data.last_page} page={page} onChange={handleChange} />
                </GridItem>
              </GridContainer>:null
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
