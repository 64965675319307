import React from "react";
//import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import BookOutlined from "@material-ui/icons/QuestionAnswer";
import QuestionAnswerRounded from "@material-ui/icons/QuestionAnswerRounded";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import HeaderLinks from "components/Header/myHeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Parallax from "components/Parallax/Parallax.js";

//ALERT
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from "react-loading-skeleton";
import GLOBAL from 'global';


// styles
import { container, title } from "assets/jss/material-kit-react.js";
import myStyles from "assets/jss/material-kit-react/views/myStyles";
import radiostyles from "assets/jss/material-kit-react/customCheckboxRadioSwitch.js";

import logoVertical from "assets/img/logo-horizontal.png";
const dashboardRoutes = [];

const styles = {
  ...radiostyles,
  container,
  title:{
    ...title,
    color:'white',
    textAlign:'center',
    width:'100%'
  },
  subtitle:{
    ...title,
    //color:'white',
    textAlign:'left',
    width:'100%',
    marginLeft:'0px !important'
  },
  answer:{
    ...title,
    textAlign:'left',
    color:'black',
    textAlign:'center',
    width:'100%'
  },
  ...myStyles,
  justifyCenter: {
    justifyContent: "center !important"
  },
  customPadding:{
    paddingTop:'10px',
    paddingBottom:'10px',
  }
}

const useStyles = makeStyles(styles);

export default function EncuestaView(props) {
  const classes = useStyles();  
  const [onrequest,setOnrequest] = React.useState(false);
  const { ...rest } = props;
  const [selectedEnabled, setSelectedEnabled] = React.useState(null);
  const wrapperDiv = classNames(
    classes.checkboxAndRadio,
    classes.checkboxAndRadioHorizontal
  );
  
  const [{ data, loading, error }, refetch] = useAxios(
    `${GLOBAL.apiURL}/api/encuesta/getEncuesta`
  ); 
  const [{ data:data2, loading:loading2, error:error2 }, refetch2] = useAxios(
    `${GLOBAL.apiURL}/api/encuesta/getResultadosEncuesta`,{manual:true}
  );
  const [{ data:dataStore, loading:loadingStore, error:errorStore }, refetchStore] = useAxios(
    {
      url:`${GLOBAL.apiURL}/api/encuesta/storeEncuesta`,
      method:'post'
    },{manual:true}
  );

  const onSendData = () => {
    let data2Send = {
      preId:data.data.preId,
      opsId:selectedEnabled
    }
    setOnrequest(true);
    //console.log("Se debe enviar ",data2Send);    
    refetchStore({
      data:data2Send
    });    
  }
  const onHandleClick = (myparam) => {
    //console.log(myparam);
    switch(myparam){
      case 0:
        refetch();
        break;
      case 1:
        refetch2();
        break;      
    }
  }

  const onHandleChange = () => {
    setSelectedEnabled(null);
    setOnrequest(false);    
  }

  if(!loading && data && data.status && data.data && data.data.respuestas && selectedEnabled===null){
    let item = data.data.respuestas.find(element => element.respuesta===true);
    //console.log("ITEM=",item);
    if(item){
      setSelectedEnabled(item.opsId);
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);   

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logoVertical} className={classes.logoImg} alt="Logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small className={classes.customParalaxBg}>
          <h1 className={classes.title}>ENCUESTA</h1>
      </Parallax>     

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
          <NavPills
                color="primary"
                onHandleClick={onHandleClick}
                tabs={[
                {
                    tabButton: "PREGUNTA",
                    tabIcon: QuestionAnswerRounded,
                    tabContent: (
                        <>                        
                          {
                            !loading && error || (data && !data.status)?
                            <SnackbarContent
                                message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button simple><i className={" fab fa-refresh"}/></Button></div>}
                                close
                                color="danger"
                                icon="info_outline"
                            />:null
                          }
                          {
                            data && data.status && data.data.respuestas.length===0?
                            <SnackbarContent
                                message={<div><span>No hay encuesta en este momento</span><Button simple><i className={" fab fa-refresh"}/></Button></div>}
                                close
                                color="info"
                                icon="info_outline"
                            />:null
                          }
                          {
                            loading?
                            Array.from(new Array(3)).map((item,index)=>(
                              <GridItem key={`card${index}`} xs={12} sm={12}>
                                <Skeleton/>
                              </GridItem>
                            )):null
                          }              
                          {
                            !loading && data && data.data?
                            <GridContainer className={classes.justifyCenter}>
                              <GridItem xs={12}>
                                <h3 className={classes.answer}>{data.data.pregunta}</h3>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={8} lg={6}>
                                {
                                  data && data.status && data.data? 
                                  data.data.respuestas.map((item,index)=>(
                                    <div key={`res${index}`} className={wrapperDiv} style={{marginBottom:'20px'}}>
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            disabled={onrequest}
                                            checked={selectedEnabled === item.opsId}
                                            onChange={() => setSelectedEnabled(item.opsId)}
                                            value={item.opsId}
                                            name={item.texto}
                                            aria-label="A"
                                            icon={
                                              <FiberManualRecord
                                                className={classes.radioUnchecked}
                                              />
                                            }
                                            checkedIcon={
                                              <FiberManualRecord className={classes.radioChecked} />
                                            }
                                            classes={{
                                              checked: classes.radio
                                            }}
                                          />
                                        }
                                        classes={{
                                          label: classes.label
                                        }}
                                        label={item.texto}
                                      />
                                    </div>
                                  )):null
                                }
                              </GridItem>
                              {
                                data && data.status && data.data.respuestas.length>0?
                                <GridItem xs={12} style={{textAlign:'center',marginBottom:'20px'}}>
                                  {
                                    onrequest && errorStore?
                                    <SnackbarContent
                                        message={<div><span>Se produjo un error al guardar la encuesta</span></div>}                                        
                                        color="danger"
                                        icon="info_outline"
                                    />:null
                                  }
                                  {
                                    onrequest && dataStore && !dataStore.status && dataStore.data?
                                    <SnackbarContent
                                        message={<div><span>{dataStore.message}</span></div>}
                                        color="danger"
                                        icon="info_outline"
                                    />:null
                                  }
                                  {
                                    !loadingStore && onrequest && dataStore && dataStore.status?
                                    <SnackbarContent
                                        message={<div><span>{dataStore.message}</span></div>}                                        
                                        color="success"
                                        icon="info_outline"
                                    />:null
                                  }
                                  {
                                    data && data.status && data.data.editar?
                                    <SnackbarContent
                                        message={<div><span>Ya se realizó la encuesta desde este equipo, puede cambiar su respuesta si así lo desea</span></div>}
                                        color="info"
                                        icon="info_outline"
                                    />:null
                                  }
                                  {
                                    onrequest && loadingStore?
                                    <div style={{textAlign:'center'}}>
                                      <CircularProgress/>
                                    </div>:null
                                  } 
                                  {
                                    !onrequest?
                                    <Button color={selectedEnabled?"primary":"warning"} disabled={selectedEnabled?false:true} onClick={()=>onSendData()}>{selectedEnabled?"ENVIAR":"RESPONDA LA ENCUESTA"}</Button>:
                                    <Button color="info" disabled={loadingStore} onClick={()=>onHandleChange()}>CAMBIAR RESPUESTA</Button>
                                  }   
                                </GridItem>:null
                              }
                            </GridContainer>:null
                          }
                        </>
                    )
                },
                {
                    tabButton: "RESULTADOS",
                    tabIcon: BookOutlined,
                    tabContent: (
                        <GridContainer className={classes.justifyCenter}>                            
                          <GridItem xs={12} sm={12} style={{marginBottom:'20px'}}>
                            {
                              !loading2 && error2 || (data2 && !data2.status)?
                              <SnackbarContent
                                  message={<div><span><b>ERROR:</b> Se produjo un error en la obtención de datos.</span><Button simple><i className={" fab fa-refresh"}/></Button></div>}
                                  close
                                  color="danger"
                                  icon="info_outline"
                              />:null
                            }
                            {
                              data2 && data2.status && data2.data.respuestas.length===0?
                              <SnackbarContent
                                  message={<div><span>No hay encuesta en este momento</span><Button simple><i className={" fab fa-refresh"}/></Button></div>}
                                  close
                                  color="info"
                                  icon="info_outline"
                              />:null
                            }
                            {
                              loading2?
                              Array.from(new Array(3)).map((item,index)=>(                                
                                <Skeleton key={`skl${index}`}/>                                
                              )):null
                            }

                            {
                              !loading2 && data2 && data2.status && data2.data?
                              <>
                                <h3 className={classes.answer}>{data2.data.pregunta}</h3>
                                {                                   
                                  data2.data.respuestas.map((item,index)=>(
                                    <div key={`resul${index}`}>
                                      <h5 className={classes.subtitle} style={{marginLeft:'0px'}}>{`${item.texto} - ${item.cantidad} (${item.porcentaje}%)`}</h5>
                                      <CustomLinearProgress
                                        variant="determinate"
                                        color="primary"
                                        value={item.porcentaje}
                                      />
                                      <br/>
                                    </div>
                                  ))
                                }
                              </>:null
                            }
                          </GridItem>                            
                        </GridContainer>
                    )
                }]}
          />            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
