import { container,title,cardTitle } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const ultimasLeyesDecretosSectionStyle = {
    customGrid:{
        padding:'10px 10px'
    },
    customCard:{
        height:'90%'        
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        ...container
    },
    section: {
        padding: "10px 0",
        textAlign: "center"
    },
    ...imagesStyle,
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "-30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign:"left",
        cursor:'pointer'
    },
    subtitle: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none"
    },
    description: {
        color: "#999"
    },
    itemGrid: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    justifyCenter: {
        justifyContent: "center !important"
    },
    cardTitle,
    smallTitle: {
        color: "#6c757d"
    },
};

export default ultimasLeyesDecretosSectionStyle;
